import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import classNames from 'classnames'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Colors from '~shared/assets/styles/colors'
import { withAccount } from '~src/AccountContext'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import Popover from '@material-ui/core/Popover'
import Dotdotdot from 'react-dotdotdot'
import IntlUtil from '~shared/utils/IntlUtil'
import PortalLabels from '~src/constants/PortalLabels'
import InnerAccountIcon from '~src/icons/inner_account.svg'

const styles = ({ spacing: { unit }, breakpoints }) => ({
  root: {
    // height: '50px',
    maxWidth: '363px',
    flexGrow: 1,
    '&:hover': {
      borderBottom: '0 !important',
    },

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  rootContainer: {
    padding: '20px 30px 20px 40px',
    maxWidth: '360px',
    height: '100%',
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '& > p': {
      margin: 0,
    },
  },

  title: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '20px',

    fontWeight: '500',

    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    marginBottom: '2px',
  },

  fullWidth: {
    width: '100% !important',
    maxWidth: '100% !important',
  },

  select: {
    maxWidth: unit * 20,
    fontSize: '0.9em',
    lineHeight: '1em',
    // height: '100%',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
    width: '100%',
    display: 'flex',
    color: Colors.Brand,
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  selectSelect: {
    minHeight: unit * 2,
    paddingLeft: unit * 0.5,
    display: 'flex',
    alignItems: 'center',
  },

  greyBackground: {
    '&:hover': {
      backgroundColor: fade(Colors.Black, 0.32),
    },
    backgroundColor: fade(Colors.Black, 0.12),
  },
  brandBackground: {
    '&:hover': {
      backgroundColor: '#7FD8D4',
    },
    backgroundColor: '#7FD8D4',
  },

  selectMenu: {
    whiteSpace: 'normal',
    height: '50px',
    padding: '0px 20px',
    paddingRight: '40px',
  },

  paper: {
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    maxWidth: unit * 50,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 4px 4px',
  },

  itemsContainer: {
    height: '100%',
    overflowY: 'auto',
    '-ms-overflow-style': 'scrollbar',

    '&>*': {
      display: 'block',
      boxSizing: 'border-box',
      height: 'auto',
      '@media (max-width: 400px)': {
        fontSize: '3vw',
      },
    },
  },
  fadeOverlay: {
    overflow: 'auto',
    '&:after': {
      opacity: '1',
      transition: 'opacity 200ms',
      content: '""',
      position: 'absolute',
      height: '25%',
      right: 0,
      bottom: 0,
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(255,255,255,1) 0%,  rgba(255,255,255,0.9) 20%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
    },
  },
  fadeOverlayDisable: {
    '&:after': {
      opacity: 0,
      width: '0',
      height: '0',
    },
  },

  hoverPopover: {
    pointerEvents: 'none',
  },

  hoverPaper: {
    padding: unit,
    backgroundColor: Colors.DarkGrey,
    color: Colors.White,
    '& p': {
      '@media (max-width: 400px)': {
        fontSize: '3vw',
      },
    },
  },
  name: {
    '@media (max-width: 400px)': {
      fontSize: '3vw',
    },
    marginRight: '15px',
    marginTop: '3px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: '400',
    [breakpoints.down('xs')]: {
      color: Colors.Black,
    },
  },
  searchInput: {
    border: 'none',
    marginBottom: '5px',
    '&:before': {
      borderColor: 'rgba(0, 0, 0, 0.05) !important',
    },
  },
  icon: {
    right: '20px',
  },
  archiveName: {
    '@media (max-width: 400px)': {
      fontSize: '3vw',
    },
    marginRight: '15px',
    marginTop: '3px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: '400',
    [breakpoints.down('xs')]: {
      color: Colors.Black,
    },
    '&:after': {
      content: '"архив"',
      color: '#E03C31',
      position: 'absolute',
      marginLeft: '5px',
      marginTop: '-1px',
      width: '100%',
      height: 0,
      [breakpoints.down('xs')]: {
        marginTop: '0px',
      },
    },
  },
})

class HeaderItemDropDown extends React.Component {
  scrollFade = React.createRef()
  state = {
    inputValue: '',
    selectedItem: [],
    isLoading: false,
    popperOpen: false,
    scrolledToEnd: false,
  }

  getSuggestions(inputValue) {
    if (this.props.accounts === null) {
      return null
    }

    let { accounts = [] } = this.props

    let matchedGroupsById = {}
    let matchedAccountGroupsByName = {}

    let result = []

    accounts.forEach(account => {
      const { accountName, groupName, groupId, merged } = account

      if (matchedGroupsById[groupId] && merged === false) {
        result.push(account)
        return
      }

      if (groupName && groupName.toLowerCase().includes(inputValue.toLowerCase())) {
        if (merged === true) {
          if (!matchedGroupsById[groupId]) {
            result.push({ ...account, name: groupName })
          }
        } else {
          result.push({ name: groupName }, account)
        }
        matchedGroupsById[groupId] = true

        return
      }

      if (!merged && accountName.toLowerCase().includes(inputValue.toLowerCase())) {
        if (groupId && !matchedAccountGroupsByName[groupId]) {
          matchedAccountGroupsByName[groupId] = groupId
          result.push({ name: groupName })
        }

        result.push(account)
      }
    })

    return result
  }

  handleClose = () => {
    this.setState({
      popperOpen: false,
    })
  }

  _handleSuggestionClick = suggestion => () => {
    this.setState({
      popperOpen: false,
    })

    const { onAccountChange } = this.props
    onAccountChange && onAccountChange(suggestion._id)
  }

  _handleInputChange = ({ target: { value } }) => {
    this.setState({ inputValue: value })
  }

  handleMouseEnter = event => {
    this.setState({ hoverEl: event.currentTarget })
  }

  handleMouseLeave = () => {
    this.setState({ hoverEl: null })
  }

  handleScroll = e => {
    const element = this.scrollFade.current || e.currentTarget
    const scrollMax = element.scrollHeight - element.clientHeight
    const { scrolledToEnd } = this.state
    let newScrolledToEnd = element.scrollTop >= scrollMax
    if (scrolledToEnd !== newScrolledToEnd) this.setState({ scrolledToEnd: newScrolledToEnd })
  }

  render() {
    const {
      classes,
      className,
      accounts = [],
      selectedAccountId,
      grey,
      isInHeader,
      isFullWidth,
    } = this.props

    let selectedAccount = accounts.find(({ _id }) => _id === selectedAccountId)

    const { popperOpen, inputValue, hoverEl, scrolledToEnd } = this.state

    const suggestions = this.getSuggestions(inputValue)
    const name =
      selectedAccount.merged === true ? selectedAccount.groupName : selectedAccount.accountName
    const hoverAccountName =
      selectedAccount.merged === true ? null : (
        <React.Fragment>
          <b>{selectedAccount.accountName}</b> /
        </React.Fragment>
      )

    const accountStatus = selectedAccount.accountStatus

    return (
      <React.Fragment>
        <div
          className={classNames(
            classes.root,
            isFullWidth && classes.fullWidth,
            isInHeader && classes.archiveTag,
            className,
          )}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={ref => (this.popperNode = ref)}
          id={isInHeader ? 'main_select_account' : 'profile_select_account'}
          style={{ cursor: 'pointer', height: '100%' }}
          onClick={() => {
            this.setState({ popperOpen: !popperOpen })
          }}
        >
          {!isInHeader ? (
            <Select
              style={{ pointerEvents: 'none' }}
              classes={{
                selectMenu: classes.selectMenu,
                select: classNames(
                  classes.selectSelect,
                  grey ? classes.greyBackground : classes.brandBackground,
                ),
                icon: classes.icon,
              }}
              className={classNames(isFullWidth && classes.fullWidth, classes.select)}
              value={selectedAccountId}
              renderValue={() => (
                <Dotdotdot
                  clamp={3}
                  className={
                    accountStatus !== 'active' && isFullWidth ? classes.archiveName : classes.name
                  }
                >
                  {name}
                </Dotdotdot>
              )}
            />
          ) : (
            <div className={classes.rootContainer}>
              <p className={classes.title}>Торговая точка</p>
              <div className={classNames(isFullWidth && classes.fullWidth, classes.select)}>
                <Dotdotdot
                  clamp={3}
                  className={accountStatus !== 'active' ? classes.archiveName : classes.name}
                >
                  {name}
                </Dotdotdot>
                <Icon style={{ alignSelf: 'flex-start' }}>keyboard_arrow_down_outlined</Icon>
              </div>
            </div>
          )}
        </div>

        <Popover
          className={classes.hoverPopover}
          classes={{
            paper: classes.hoverPaper,
          }}
          open={!!hoverEl}
          anchorEl={hoverEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={this.handleMouseLeave}
          disableRestoreFocus
        >
          <Typography style={{ color: Colors.White }}>
            {hoverAccountName}
            {selectedAccount.groupName}
          </Typography>
        </Popover>

        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={popperOpen}
          onClose={this.handleClose}
          anchorEl={this.popperNode}
          transitionDuration={0}
          classes={{
            paper: classes.paper,
          }}
          PaperProps={{ square: true }}
          marginThreshold={0}
        >
          <TextField
            autoFocus
            fullWidth
            underline={false}
            value={inputValue}
            onChange={this._handleInputChange}
            placeholder={IntlUtil.label(PortalLabels.SEARCH)}
            className={classes.searchInput}
            InputProps={{
              style: !isFullWidth
                ? { padding: `20px 40px`, boxSizing: 'border-box' }
                : { padding: `20px 20px`, boxSizing: 'border-box' },
              className: classes.searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: '#D0D0D0' }}>search</Icon>
                </InputAdornment>
              ),
              autoComplete: 'off',
            }}
          />

          <div
            className={classNames(classes.itemsContainer, classes.fadeOverlay, {
              [classes.fadeOverlayDisable]: scrolledToEnd,
            })}
            ref={domeElement => {
              this.scrollFade.current = domeElement
              if (domeElement) this.handleScroll()
            }}
            onScroll={this.handleScroll}
          >
            {suggestions &&
              (suggestions.length === 0 ? (
                <MenuItem>No results found</MenuItem>
              ) : (
                suggestions.map((suggestion, index) => (
                  <SuggestionItem
                    isSelected={suggestion._id === selectedAccountId}
                    onClick={this._handleSuggestionClick(suggestion)}
                    key={index}
                    suggestion={suggestion}
                    index={index}
                  />
                ))
              ))}
          </div>
        </Popover>
      </React.Fragment>
    )
  }
}

const suggestionStyles = ({ spacing: { unit }, breakpoints }) => ({
  root: {
    whiteSpace: 'normal',
    '&:hover': {
      backgroundColor: '#D5F0EE',
    },
    paddingLeft: unit * 5 + 4,

    fontFamily: 'Roboto',
    fontWeight: 500,
  },

  group: {
    paddingLeft: 40,
    // backgroundColor: fade(Colors.CoolGrey, 0.2),
    pointerEvents: 'none',
    color: '#9A9A9A',
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
    },
  },
  groupItem: {
    paddingLeft: 44,
    '&:before': {
      content: '""',
      display: 'inline-block',
      marginRight: 10,
      width: 11,
      height: 16,
      background: `url(${InnerAccountIcon}) no-repeat`,
    },
    [breakpoints.down('xs')]: {
      paddingLeft: 22,
    },
  },
  merged: {
    paddingLeft: 40,
    textTransform: 'uppercase',
    '&:before': {
      content: 'none !important',
    },
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
    },
  },

  selected: {
    backgroundColor: fade(Colors.Aqua, 0.25),
    '&:hover': {
      backgroundColor: fade(Colors.Aqua, 0.75),
    },
  },

  inactive: {
    '&:after': {
      content: '"архив"',
      color: '#E03C31',
      position: 'absolute',
      marginLeft: '7px',
      fontSize: '14px',
      fontWeight: '400',
      width: '100%',
      height: 0,
      [breakpoints.down('xs')]: {
        marginTop: '3px',
      },
    },
  },
})

const SuggestionItem = withStyles(suggestionStyles)(
  ({ classes, suggestion, isSelected, ...rest }) => {
    const { accountName, accountStatus, _id, name, merged } = suggestion

    const isGroup = !!name && !merged
    const isMerged = !!name && merged

    return (
      <MenuItem
        value={_id}
        className={classNames(
          classes.root,
          isMerged && classes.merged,
          isGroup ? classes.group : classes.groupItem,
          isSelected && classes.selected,
          accountStatus && accountStatus !== 'active' && classes.inactive,
        )}
        {...rest}
      >
        {merged === true || !!name ? name : accountName}
      </MenuItem>
    )
  },
)

export default withAccount(withStyles(styles)(HeaderItemDropDown))

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Icon from '@material-ui/core/Icon/Icon'
import queryString from 'query-string'
import Colors from '~shared/assets/styles/colors'
import withRouter from 'react-router-dom/withRouter'
import AnalyticsEvents from '~shared/utils/AnalyticsEvents'

const styles = ({ breakpoints }) => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    bottom: 20,
    left: 20,
    transition: '0.3s',
    '@media print': {
      display: 'none',
    },
  },
  open: {
    transform: 'none !important',
    width: 400,
    [breakpoints.down('xs')]: {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      bottom: 10,
      left: 10,
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(100, 100, 100, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(100, 100, 100, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(100, 100, 100, 0)',
    },
  },
  button: {
    height: 48,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    color: Colors.White,
    animation: 'pulse 2s infinite',
  },
  buttonWithLabel: {
    padding: '0 1rem',
  },
  buttonLabel: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingLeft: '0.4em',
  },
  hidden: {
    opacity: 0,
  },
  iframe: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    flex: 1,
    border: 0,
    height: 0,
    width: 0,
    transition: '0.3s',
    opacity: 0,
    pointerEvents: 'none',
  },
  iframeOpen: {
    opacity: 1,
    pointerEvents: 'auto',
    boxShadow: '0 0 5px rgba(0,0,0,0.5)',
    width: 400,
    height: 500,
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 40px)',
    [breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      bottom: 0,
      left: 0,
      borderRadius: 0,
    },
  },
})

function Chatbot(props) {
  const {
    classes,
    path,
    color,
    placeholder = '',
    buttonLabel,
    avatar = {},
    nickname,
    subtitle,
    token,
    member,
    location,
  } = props
  const [open, setOpen] = React.useState(true)

  React.useEffect(() => {
    function sendToken(e) {
      if (e.data !== 'start-chatbot') return

      // send token to iframe
      const iframe = document.getElementById('ChatbotIframe')
      iframe.contentWindow.postMessage({ id: 'token', token }, '*')
    }

    window.addEventListener('message', sendToken)
    return () => window.removeEventListener('message', sendToken)
  }, [])

  React.useEffect(() => {
    function closeChatbot(e) {
      if (e.data === 'close-chatbot') setOpen(false)
    }

    window.addEventListener('message', closeChatbot)
    return () => window.removeEventListener('message', closeChatbot)
  }, [])

  if (!path || !token) {
    console.log("Can't render Chatbot: required parameters are not available")
    return null
  }

  const srcUrl = `${path}?${queryString.stringify({
    p: placeholder,
    a: avatar.path,
    n: nickname,
    s: subtitle,
    m: member._id,
  })}`

  return (
    <aside id="Chatbot" className={classNames(classes.root, { [classes.open]: open })}>
      <IconButton
        className={classNames(
          classes.button,
          { [classes.buttonWithLabel]: buttonLabel },
          { [classes.hidden]: open },
        )}
        onClick={() => {
          setOpen(true)
          AnalyticsEvents.pushChatBotOpen()
        }}
        style={{ backgroundColor: color }}
      >
        <Icon className={classes.icon}>chat_bubble_outline</Icon>
        {buttonLabel && <span className={classes.buttonLabel}>{buttonLabel}</span>}
      </IconButton>
      <iframe
        className={classNames(classes.iframe, { [classes.iframeOpen]: open })}
        id="ChatbotIframe"
        title="Chatbot"
        src={srcUrl}
      ></iframe>
    </aside>
  )
}

export default withRouter(withStyles(styles)(Chatbot))

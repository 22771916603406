import React from 'react'

const PopupContext = React.createContext(false)
const { Consumer: PopupConsumer } = PopupContext

const withPopup = Component => props => (
  <PopupConsumer>{value => <Component {...props} {...value} />}</PopupConsumer>
)

/**
 *
 * @return {{popup:boolean, listenToOuterClose:Subscriber.subscribe}}
 */
const usePopup = () => React.useContext(PopupContext)

export { withPopup, usePopup }

export default PopupContext

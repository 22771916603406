import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import HeaderItem from '~components/molecules/HeaderItem'
import Colors from '~shared/assets/styles/colors'

const styles = ({ breakpoints }) => ({
  root: {
    position: 'relative',
    height: '100%',
    padding: '0 !important',
  },
  button: {
    padding: '8px 0px 15px',
    height: '100%',
    boxSizing: 'border-box',

    [breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  badge: {
    backgroundColor: Colors.Brand,
    borderRadius: 5,
    height: 10,
    width: 10,
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: 3,
    marginTop: -8,
  },
  attention: {
    position: 'absolute',
    top: '0',
    right: 0,
    width: 300,
    padding: '15px 50px 40px',
    backgroundColor: '#fff',
    borderRadius: 5,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('xs')]: {
      padding: '20px 40px 20px',

      '& > span': {
        justifySelf: 'flex-end',
        marginLeft: 'auto',
      },

      '& > span:nth-child(2n)': {
        display: 'none',
      },
    },

    '& > p': {
      fontFamily: 'Roboto',
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
  },
  response: {
    color: Colors.Brand,
    cursor: 'pointer',
    margin: 0,
    marginTop: '20px',
    textTransform: 'uppercase',

    [breakpoints.down('xs')]: {
      marginRight: 'auto',
    },
  },
})

class HeaderItemNotificationCenter extends React.Component {
  render() {
    const {
      classes,
      member: { hasNotifications },
      api,
      ...props
    } = this.props

    return (
      <div className={classes.root} id="notificationHeaderIcon">
        <HeaderItem
          {...props}
          xsIconVisible
          hasNotifications={hasNotifications}
          className={classes.button}
        />
        {/*{hasNotifications && <Typography className={classNames(classes.badge)} />}*/}
      </div>
    )
  }
}

export default withStyles(styles)(HeaderItemNotificationCenter)

import starIcon from '~shared/assets/img/icons/star.svg'
import crownIcon from '~shared/assets/img/icons/crown.svg'
import starIconFill from '~shared/assets/img/icons/star-fill.svg'
import crownIconFill from '~shared/assets/img/icons/crown-fill.svg'
import { ReactComponent as starTransparent } from '~shared/assets/img/icons/star-gray.svg'
import { ReactComponent as crownTransparent} from '~shared/assets/img/icons/crown-gray.svg'
import { ReactComponent as StarIconSvg } from '~shared/assets/img/icons/star.svg'
import { ReactComponent as CrownIconSvg } from '~shared/assets/img/icons/crown.svg'
import { ReactComponent as StarIconFillSvg } from '~shared/assets/img/icons/star-fill.svg'
import { ReactComponent as CrownIconFillSvg } from '~shared/assets/img/icons/crown-fill.svg'


export const typePointToIcon = {
  default: starIcon,
  expert: starIcon,
  business: crownIcon,
  businessrrp: crownIcon,
}

export const typePointToIconFill = {
  default: starIconFill,
  expert: starIconFill,
  business: crownIconFill,
  businessrrp: crownIconFill,
}

export const typePointToIconSvg = {
  default: StarIconSvg,
  expert: StarIconSvg,
  business: CrownIconSvg,
  businessrrp: CrownIconSvg,
}

export const typePointToIconFillSvg = {
  default: StarIconFillSvg,
  expert: StarIconFillSvg,
  business: CrownIconFillSvg,
  businessrrp: CrownIconFillSvg,
}

export const typePointToIconTransparentSvg = {
  default: starTransparent,
  expert: starTransparent,
  business: crownTransparent,
  businessrrp: crownTransparent,
}

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import ScrollToBottom from '~shared/components/molecules/ScrollToBottom/ScrollToBottom'

const styles = ({ spacing: { unit } }) => ({
  root: {
    boxSizing: 'border-box',
    padding: unit + 4,
    flex: 1,
  }
})

export default withStyles(styles)(({ classes, className, style, children, onScrolledToBottom }) => {
  return (
    <ScrollToBottom
      className={classNames(classes.root, className)}
      onScrolledToBottom={onScrolledToBottom}
      style={style}
    >
      {children}
    </ScrollToBottom>
  )
})

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography/Typography'
import HeaderItem from '~components/molecules/HeaderItem'

import IntlUtil from '~shared/utils/IntlUtil'
import classNames from 'classnames'
import Colors from '~shared/assets/styles/colors'
import { Hidden } from '@material-ui/core'

const styles = () => ({
  root: {
    position: 'relative',
  },
  numItems: {
    color: Colors.White,
    backgroundColor: Colors.Brand,
    borderRadius: 10,
    height: 20,
    minWidth: 10,
    padding: '0 5px',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    top: 5,
  },
})

class HeaderItemOrders extends React.Component {
  render() {
    const {
      classes,
      member: { numCartItems },
      ...props
    } = this.props
    const to = '/shopping-cart'

    if (numCartItems > 0)
      return (
        <Hidden xsDown>
          <div className={classes.root}>
            <HeaderItem {...props} to={to} />
            <Typography className={classNames(classes.numItems)}>
              {IntlUtil.num(numCartItems)}
            </Typography>
          </div>
        </Hidden>
      )

    return <HeaderItem {...props} to={to} />
  }
}

export default withStyles(styles)(HeaderItemOrders)

import React from 'react'
import Colors from '~shared/assets/styles/colors'

export const MAX_WIDTH = 1440

class PageModule extends React.Component {
  constructor(props) {
    super(props)
    const { spacingNotifier, backgroundColor } = props

    this.state = { backgroundColor, spacing: props.spacing }

    spacingNotifier && spacingNotifier(this._handleSpacingChange)
  }

  render() {
    const {
      className,
      extraSpacingTop = 0,
      children,
      size,
      boxShadow,
      variant,
      background = 'spectrum5',
      dark,
      isPrint,
      ...props
    } = this.props
    const { backgroundColor, spacing = {} } = this.state

    const { maxWidths = {} } = spacing
    const maxWidth = maxWidths[size] || MAX_WIDTH

    const sizeSpecificSpacing = spacing[size] || {}

    const { all: defaultAll } = spacing
    const { all = defaultAll } = sizeSpecificSpacing

    const {
      left: defaultLeft = all,
      right: defaultRight = all,
      top: defaultTop = all,
      bottom: defaultBottom = all,
    } = spacing
    const {
      left = defaultLeft,
      right = defaultRight,
      top = defaultTop,
      bottom = defaultBottom,
    } = sizeSpecificSpacing

    const spacingMode = backgroundColor || variant ? 'padding' : 'margin'

    const spacingStyles = {
      [spacingMode + 'Top']: top + extraSpacingTop || undefined,
      [spacingMode + 'Bottom']: bottom,
      paddingLeft: left,
      paddingRight: right,
    }

    let backgroundSpectrum = '' || backgroundColor
    let color = ''
    let isDark = dark

    if (variant === 'black') {
      backgroundSpectrum = Colors.Black
      color = Colors.White
      isDark = true
    } else if (variant === 'colored') {
      const selectedSpectrum = this.props.parent
        ? this.props.parent.background || background
        : background
      backgroundSpectrum = Colors[selectedSpectrum] || Colors.Black

      if (selectedSpectrum === 'white') {
        color = Colors.Black
        isDark = false
      } else {
        color = Colors.White
        isDark = true
      }
    } else if (variant === 'white') {
      backgroundSpectrum = Colors.White
      color = Colors.Black
      isDark = false
    }

    if (isPrint && backgroundSpectrum !== Colors.White) {
      return (
        <div className={className} style={{ ...spacingStyles, boxShadow }}>
          {React.Children.map(
            children,
            child =>
              child &&
              React.cloneElement(child, {
                ...props,
                isPrint,
                dark: isDark,
                style: { maxWidth, marginLeft: 'auto', marginRight: 'auto' },
                bgSetter: this._setBackground,
                size,
              }),
          )}
        </div>
      )
    }

    return (
      <div
        className={className}
        style={{
          transition: 'background-color 500ms ease-in-out',
          color,
          background: backgroundSpectrum,
          ...spacingStyles,
          boxShadow,
        }}
      >
        {React.Children.map(
          children,
          child =>
            child &&
            React.cloneElement(child, {
              ...props,
              isPrint,
              dark: isDark,
              style: { maxWidth, marginLeft: 'auto', marginRight: 'auto' },
              bgSetter: this._setBackground,
              size,
            }),
        )}
      </div>
    )
  }

  _handleSpacingChange = spacing => {
    const { onSpacingChange } = this.props
    this.setState({ spacing })
    onSpacingChange && onSpacingChange(spacing)
  }

  _setBackground = backgroundColor => {
    backgroundColor !== this.state.backgroundColor && this.setState({ backgroundColor })
  }
}

const asPageModule = spacing => Component => ({ children, ...rest }) => (
  <PageModule spacing={spacing} {...rest}>
    <Component>{children}</Component>
  </PageModule>
)

/**
 * Both Notifier and Handler as one method
 * @param modifier will be called each time value changed and will set new value
 * @returns method to set either method (as notifier) or value (to notify by calling method)
 */
const notiHandler = modifier => {
  let method, value

  return valueOrMethod => {
    if (
      (typeof valueOrMethod === 'function' && (method = valueOrMethod)) ||
      valueOrMethod === value ||
      !method
    )
      return

    value = valueOrMethod

    return method((modifier && modifier(value)) || value)
  }
}

export default PageModule
export { asPageModule, notiHandler }

import React, { useEffect, useRef, useState } from 'react'
import Colors from '~shared/assets/styles/colors'
import Icon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import { withApi } from '~shared/api/ApiContext'
import { Link } from 'react-router-dom'
import { useSearchHistory, useSearchResults } from '~components/hooks/Search'
import classNames from 'classnames'

const styles = ({ spacing: { unit }, breakpoints }) => ({
  searchContainer: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    position: 'relative',
    marginLeft: 'auto',
    maxWidth: '327px',
    height: '100%',
    padding: '42px 20px 36px 42px',
    boxSizing: 'border-box',
    borderLeft: `1px solid ${Colors['Black005']}`,
  },
  searchInputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  searchIcon: {
    color: '#D0D0D0',
    fontSize: '24px',
    marginRight: '10px',
  },
  searchInput: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',

    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.48px',
    color: Colors.Black,

    '&::placeholder': {
      color: '#D0D0D0',
    },
  },
  searchResultsContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: Colors.White,
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 4px 4px',
    padding: '36px 0px 36px',
    boxSizing: 'border-box',
    cursor: 'default',
  },
  searchResults: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  searchResultLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '11px 40px 9px 68px',
    boxSizing: 'border-box',

    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.14px',
    cursor: 'pointer',

    '& > p': {
      margin: 0,

      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    '&:hover': {
      backgroundColor: '#D5F0EE',
    },
  },
  resultText: {
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    color: '#000000',

    maxWidth: '70%',
  },
  resultPage: {
    color: '#9A9A9A',
    maxWidth: '27%',
  },
  emptyResult: {
    margin: 0,
    color: '#9A9A9A',
    justifyContent: 'flex-start',
    cursor: 'default',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const getElementPosition = (element, callback, startPosition = { t: 0, l: 0 }) => {
  if (element) {
    const position = element.getBoundingClientRect()
    const top = position.y
    const left = position.x
    if (top !== startPosition.t || left !== startPosition.l) {
      return setTimeout(() => {
        getElementPosition(element, callback, { t: top, l: left })
      }, 300)
    }
    callback(position)
  }
}

const SearchBar = ({ classes, api }) => {
  const [positionInfo, setPositionInfo] = useState({ left: 0, right: 0 })
  const [searchResults, searchString, changeSearchString, loadMoreScroll] = useSearchResults(api)
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [searchHistory, addSearchHistory] = useSearchHistory(api)

  const searchContainerRef = useRef(null)
  const searchInputRef = useRef(null)

  useEffect(() => {
    getElementPosition(searchContainerRef.current, position => {
      if (position.x !== positionInfo.left) {
        setPositionInfo(({ right }) => ({ left: position.x, right }))
      }
    })
    if (window.innerWidth > 1440) {
      setPositionInfo(({ left }) => ({ left, right: (window.innerWidth - 1440) / 2 }))
    }
  }, [searchContainerRef.current])

  useEffect(() => {
    // close search results on click outside
    const closeSearchResults = e => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(e.target)) {
        fadeSearchResults()
      }
    }
    document.addEventListener('click', closeSearchResults)
    return () => document.removeEventListener('click', closeSearchResults)
  }, [])

  const blurSearchInput = () => {
    if (searchInputRef.current) searchInputRef.current.blur()
  }

  const fadeSearchResults = () => {
    setShowSearchResults(false)
  }
  const onFocusSearchInput = () => {
    if (!showSearchResults) setShowSearchResults(true)
  }

  const onClickLink = searchElement => async e => {
    e.preventDefault()
    blurSearchInput()
    fadeSearchResults()
    await addSearchHistory(searchElement)
    api.connector.onNavigateTo(searchElement.slug)
  }

  const clearSearchString = () => {
    changeSearchString({ target: { value: '' } })
  }

  const onClickSearchIcon = () => {
    if (searchString) {
      clearSearchString()
    } else {
      if (searchInputRef?.current) searchInputRef.current.focus()
    }
  }

  const searchDataToRender = searchResults ?? (!searchString && searchHistory)

  let searchResultsRender = null

  if (searchDataToRender) {
    if (searchDataToRender.length > 0) {
      searchResultsRender = searchDataToRender.map(result => (
        <Link
          to={result?.slug}
          className={classes.searchResultLine}
          key={result._id}
          onClick={onClickLink(result)}
        >
          <p className={classes.resultText}>
            {result.title ?? result.description ?? result.content}
          </p>
          <p className={classes.resultPage}>{result.sectionName}</p>
        </Link>
      ))
    } else {
      searchResultsRender = (
        <p
          className={classNames(classes.searchResultLine, classes.resultText, classes.emptyResult)}
        >
          Ничего не найдено
        </p>
      )
    }
  }

  return (
    <div
      className={classes.searchContainer}
      ref={searchContainerRef}
      onClick={e => {
        e.stopPropagation()
        if (searchInputRef.current && !showSearchResults) searchInputRef.current.focus()
      }}
    >
      <div className={classes.searchInputContainer}>
        <Icon
          id={'mainpage_search_button'}
          className={classes.searchIcon}
          onClick={onClickSearchIcon}
        >
          {searchString ? 'close' : 'search'}
        </Icon>
        <input
          type="text"
          id={'mainpage_search_text'}
          className={classes.searchInput}
          value={searchString}
          onChange={changeSearchString}
          placeholder="Поиск..."
          onFocus={onFocusSearchInput}
          ref={searchInputRef}
          autoComplete="off"
        />
      </div>
      {showSearchResults && searchResultsRender ? (
        <div
          className={classes.searchResultsContainer}
          style={{ width: `calc(100vw - ${positionInfo.left}px - ${positionInfo.right}px)` }}
        >
          <div className={classes.searchResults} onScroll={loadMoreScroll}>
            {searchResultsRender}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default withApi(withStyles(styles)(SearchBar))

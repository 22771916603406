import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { withApi } from '~shared/api/ApiContext'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '~shared/assets/img/logo-black.svg'
import Colors from '~shared/assets/styles/colors'
import HeaderItem from '~components/molecules/HeaderItem'
import HeaderItemDropDown from '~components/molecules/HeaderItemDropDown'
import HeaderItemOrders from '~components/molecules/HeaderItemOrders'
import HeaderItemNotificationCenter from '~components/molecules/HeaderItemNotificationCenter'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import HeaderItemPoints from '~components/molecules/HeaderItemPoints'
import PageData from '~shared/utils/PageData'
import AppMode from '~components/organisms/AppMode'
import classnames from 'classnames'
import HeaderItemCash from '~components/molecules/HeaderItemCash'
import HealthWarning from '~components/molecules/HealthWarning'
import withWidth from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'
import SearchBar from '~components/molecules/SearchBar'
import IconLock from '~src/icons/lock.svg'
import IconDemo from '~src/icons/demo.svg'

const components = {
  HeaderItem,
  HeaderItemPoints,
  HeaderItemDropDown,
  HeaderItemCash,
  HeaderItemOrders,
  HeaderItemNotificationCenter,
}

const componentsIds = {
  HeaderItem: 'main_menu_profile',
  HeaderItemPoints: 'main_menu_points',
  HeaderItemDropDown: 'main_menu_selectAccount',
  HeaderItemNotificationCenter: 'main_menu_notification_center',
}

const styles = ({ breakpoints, spacing: { unit } }) => ({
  appBar: {
    paddingLeft: 0,
    backgroundColor: Colors.White,
    transition: 'box-shadow 300ms ease-in-out',
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0), 0 2px 1px -1px rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0)',

    boxSizing: 'border-box',
  },
  shadowed: {
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  toolBarContainer: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 0,
    height: unit * 8 + 7,
    zIndex: 3,
    maxWidth: '1440px',
    boxSizing: 'border-box',

    [breakpoints.down('xs')]: {
      height: '60px',
      padding: '3px 20px 0px',
    },
  },
  toolbar: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  itemsContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  item: {
    minWidth: unit * 7 - 4,
    height: '100%',
    paddingBottom: 2,
    // '&:hover': {
    //   paddingBottom: 0,
    //   borderBottom: `2px solid ${Colors.Brand}`,
    //   marginBottom: 2,
    // },
    [breakpoints.down('sm')]: {
      minWidth: unit * 4 - 4,
    },
  },
  fullWidth: {
    width: '100%',
  },
  flex: {
    flex: 1,
  },
  logo: {
    position: 'relative',
    alignSelf: 'flex-start',
    display: 'flex',
    marginRight: 5,
  },
  platformName: {
    flip: false,
    alignSelf: 'flex-end',
    bottom: 0,
  },
  platformIcon: {
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',
    paddingTop: '13px',
    boxSizing: 'border-box',

    [breakpoints.down('sm')]: {
      maxHeight: '86%',
      paddingTop: 0,
    },
  },
  platformIconContainer: {
    height: '100%',
    maxWidth: '188px',
    alignItems: 'center',
    display: 'flex',
    borderRight: `1px solid ${Colors['Black005']}`,
    padding: '0 15px',

    [breakpoints.down('xs')]: {
      borderRight: 'none',
    },
  },

  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
  },

  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    // width: '475px',
    // padding: '8px 0px 15px',
    boxSizing: 'border-box',

    flexGrow: 1,

    '& > *': {
      borderLeft: `1px solid ${Colors['Black005']}`,
      flexGrow: 1,
      padding: '8px 0px 15px',
      boxSizing: 'border-box',

      [breakpoints.down('xs')]: {
        borderLeft: 'none',
        marginRight: '10px',

        '&:last-child': {
          marginRight: 0,
        },
      },
    },

    [breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  h100: {
    height: '100%',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  archiveAccountBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    backgroundColor: '#EBEBEB',
    padding: '8px 20px',
    '&:before': {
      content: '""',
      display: 'inline-block',
      marginRight: 10,
      minWidth: 24,
      minHeight: 24,
      background: `url(${IconLock}) no-repeat`,
      [breakpoints.down('xs')]: {
        display: 'none',
        marginRight: 0,
      },
    },
    [breakpoints.down('xs')]: {
      textAlign: 'left',
      '&:after': {
        content: '""',
        display: 'inline-block',
        minWidth: 24,
        minHeight: 24,
        background: `url(${IconLock}) no-repeat`,
      },
    },
  },
  demoMemberBar: {
    background:
      'repeating-linear-gradient(135deg, #1A8E88, #1A8E88 16px, #00817B 16px, #00817B 32px)',
    color: Colors.White,
    '&:before': {
      background: `url(${IconDemo}) no-repeat`,
    },
    [breakpoints.down('xs')]: {
      '&:after': {
        background: `url(${IconDemo}) no-repeat`,
      },
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
})

const Header = withStyles(styles)(
  ({
    classes,
    renderDrawerIcon,
    member,
    labels,
    mode,
    data: { items = [] },
    id,
    healthWarningDesktop,
    healthWarningMobile,
    width,
    onMount,
    onUnmount,
    api,
    history,
    contentComponentName,
  }) => {
    const [scrolled, setScrolled] = useState(false)
    // const [showLogoutDialog, setShowLogoutDialog] = useState(false)

    const { accounts, selectedAccountId, isGuest } = member || {}
    const selectedAccount = accounts?.find(({ _id }) => _id === selectedAccountId)

    const selectedAccountIsArchive = selectedAccount?.accountStatus !== 'active' && member

    useEffect(() => {
      if (!selectedAccountIsArchive) return
      const unavailableMenuItems = [
        '/my-programs',
        '/loyalty',
        '/world-of-tobacco',
        '/my-programs-kb',
        '/edu-actions',
        '/notifications',
        '/tasks',
        '/site-services',
      ]

      const isUnavailableRoute =
        history.location?.pathname === '/' ||
        unavailableMenuItems.some(route => {
          return history.location?.pathname.indexOf(route) !== -1
        })

      if (isUnavailableRoute) {
        history.push('/profile')
      }
    }, [
      history,
      member,
      history.location?.pathname,
      history.location?.search,
      selectedAccountIsArchive,
    ])

    useEffect(() => {
      onMount && onMount()
      window.addEventListener('scroll', handleScroll.bind(this))
      return () => {
        onUnmount && onUnmount()
        window.removeEventListener('scroll', handleScroll.bind(this))
      }
    }, [])

    const handleScroll = () => {
      if (!scrolled) {
        if (window.scrollY > 10) {
          setScrolled(true)
        }
      } else if (window.scrollY === 0) {
        setScrolled(false)
      }
    }

    const createHeaderItem = ({ component, props, _link }, index) => {
      const Component = components[component]

      if (!Component) return null
      if (haveHeaderItemDropDown && component === 'HeaderItemDropDown') return null

      return (
        <Component
          key={'item_' + index}
          {...props}
          buttonId={componentsIds[component]}
          _link={_link}
          member={member}
          className={classes.item}
          api={api}
          isInHeader={true}
        />
      )
    }

    let { settings: { general: { platformName, platformIcon } = {} } = {} } =
      PageData.appFrame || {}
    platformName = platformName || ''
    const ltrStyle = platformIcon ? { textAlign: 'left' } : { flip: false, direction: 'ltr' }
    const linkClass = platformIcon
      ? classnames(classes.logo, classes.platformIconContainer)
      : classes.logo

    const accountDropDown = items.find(({ component }) => component === 'HeaderItemDropDown')

    const haveHeaderItemDropDown = width === 'xs' ? accountDropDown : null

    const leftItems = accountDropDown ? createHeaderItem(accountDropDown, 199) : null

    const rightItems = items
      .filter(({ component }) => component !== 'HeaderItemDropDown' && component !== 'SearchBar')
      .map(item => {
        item.props = {
          ...item.props,
          selectedAccountIsArchive,
        }

        return createHeaderItem(item)
      })

    const showSearch =
      width !== 'xs' && width !== 'sm' && items.find(({ component }) => component === 'SearchBar')

    const showIsArchive =
      member && selectedAccountIsArchive && contentComponentName !== 'LoginPageMobilephone'

    const showIsGuest = isGuest && contentComponentName !== 'LoginPageMobilephone'

    return (
      <AppBar
        id={id}
        position="fixed"
        className={`${classes.appBar} ${scrolled ? classes.shadowed : ''}`}
      >
        {mode && <AppMode {...mode} />}

        {haveHeaderItemDropDown ? <HeaderItemDropDown isFullWidth={true} /> : null}

        <div className={classes.headerContainer}>
          <div className={classes.toolBarContainer}>
            <Toolbar className={classes.toolbar} disableGutters>
              <Grid
                container
                item
                xs={12}
                lg={12}
                className={classnames(classes.h100, classes.noWrap)}
              >
                <Grid container item xs="auto" md={8} className={classes.h100}>
                  <div className={classes.leftContainer}>
                    {renderDrawerIcon && renderDrawerIcon()}
                    <Link id={'main_page_logo'} to={'/'} className={linkClass} style={ltrStyle}>
                      {platformIcon ? (
                        <img
                          src={platformIcon.path}
                          alt="Platform Icon"
                          className={classes.platformIcon}
                        />
                      ) : (
                        <React.Fragment>
                          <img src={logo} alt="" />
                          {platformName && (
                            <Typography
                              className={classes.platformName}
                              variant={'caption'}
                              color={'primary'}
                            >
                              {platformName.toUpperCase()}
                            </Typography>
                          )}
                        </React.Fragment>
                      )}
                    </Link>
                    {leftItems}
                    {showSearch ? <SearchBar /> : null}
                  </div>
                </Grid>
                <Grid container item xs="auto" md={4}>
                  <div className={classes.rightContainer} id="header-right">
                    {/*<div className={classes.itemsContainer}>{rightItems}</div>*/}
                    {rightItems}
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </div>

          {showIsArchive && (
            <div className={classes.archiveAccountBar}>{labels.ARCHIVE_ACCOUNT_NOTIFICATION}</div>
          )}
          {showIsGuest && (
            <div className={classnames(classes.archiveAccountBar, classes.demoMemberBar)}>
              {labels.GUEST_USER_NOTIFICATION}
            </div>
          )}
        </div>

        {(healthWarningDesktop || healthWarningMobile) && (
          <HealthWarning
            healthWarningDesktop={healthWarningDesktop}
            healthWarningMobile={healthWarningMobile}
          />
        )}
      </AppBar>
    )
  },
)

export default withWidth()(withApi(Header))

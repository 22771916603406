import React, { useEffect, useState } from 'react'
import { WidthContext } from '../lib/WidthContext'

export const WidthProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <WidthContext.Provider value={width}>{children}</WidthContext.Provider>
}

import { useCallback, useContext, useMemo } from 'react'
import { HistoryStoreContext } from './HistoryStoreContext'

/**
 *
 * @return {{ historyStore: import('../type/historyStore.type').HistoryStoreType, goBackInHistoryStore: () => void}}
 */
export const useHistoryStore = () => {
  const { historyStore, setHistoryStore } = useContext(HistoryStoreContext)

  const goBackInHistoryStore = useCallback(() => {
    if (historyStore?.previousFullPage) {
      setHistoryStore(prev => ({
        ...prev,
        currentFullPage: historyStore.historyFullPages[1],
        previousFullPage: historyStore.historyFullPages[2],
        historyFullPages: historyStore.historyFullPages.slice(1),
      }))
    }
  }, [historyStore, setHistoryStore])

  return { historyStore, goBackInHistoryStore }
}

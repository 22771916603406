import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { RichText } from '~components/molecules/RichTextModule'
import ImageBase from '~components/atoms/ImageBase/ImageBase'

const styles = ({ breakpoints }) => ({
  // root: {
  //   height: '10vh',
  //   // zIndex: 9999,
  // },
  // text: {
  //   height: '100%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   borderTop: '1px solid rgba(0,0,0,0.25)',
  //   padding: '12px !important',
  //   backgroundColor: 'white',
  //   textAlign: 'center',
  //   '& > div': {
  //     width: '100%',
  //   },
  //
  //   '& p': {
  //     fontFamily: 'Helvetica, Roboto, sans-serif',
  //     fontSize: 'max(2vh, 2vw)',
  //   },
  root: {
    height: '10vh',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid rgba(0,0,0,0.25)',
    padding: '12px',
    backgroundColor: 'white',
  },
  text: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Helvetica, Roboto, sans-serif',
    fontSize: 'min(2.5vh, 2rem)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },

  imageDesktop: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  imageMobile: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
})

class HealthWarning extends React.Component {
  render() {
    const { classes, className, healthWarningMobile, healthWarningDesktop } = this.props

    let { path: pathDesktop } = healthWarningDesktop || {}
    let { path: pathMobile } = healthWarningMobile || {}

    if (!pathDesktop && !pathMobile) return null

    if (!pathDesktop) pathDesktop = pathMobile
    else if (!pathMobile) pathMobile = pathDesktop

    return (
      <div className={classNames(classes.root, className)}>
        {/*<p className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />*/}

        <img
          src={pathDesktop}
          alt={'health warning'}
          className={classNames(classes.image, classes.imageDesktop)}
        />
        <img
          src={pathMobile}
          alt={'health warning'}
          className={classNames(classes.image, classes.imageMobile)}
        />

        {/*<RichText content={text} className={classes.text} horizontalPadding={0} />*/}
      </div>
    )
  }
}

export default withStyles(styles)(HealthWarning)

import React, { useContext, useMemo } from 'react'

/**
 * @typedef {{
 *   meta: {
 *    isMobile: boolean,
 *    isTablet: boolean,
 *    isDesktop: boolean,
 *  }
 * }} AppFrameContextValue
 */

const AppFrameContext = React.createContext({})

const { Provider: AppFrameProvider, Consumer: AppFrameConsumer } = AppFrameContext

const withAppFrame = Component => props => {
  return <AppFrameConsumer>{data => <Component {...props} appFrame={data} />}</AppFrameConsumer>
}

/**
 *
 * @return {AppFrameContextValue}
 */
const useAppFrame = () => {
  /**@type {AppFrameContextValue} */
  const appFrame = useContext(AppFrameContext)

  return appFrame
}

export { AppFrameProvider, AppFrameConsumer, withAppFrame, useAppFrame }

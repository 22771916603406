import Colors from '~shared/assets/styles/colors'
import * as PropTypes from 'prop-types'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  menuIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: unit * 10,
    zIndex: 1,
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.05)',
    padding: '13px 0px 17px',
    boxSizing: 'border-box',

    [breakpoints.down('xs')]: {
      width: '32px',
      padding: '0px',
      borderRight: 'none',
    },

    '& > p': {
      fontFamily: 'Roboto, sans-serif',
      margin: 0,
      color: Colors.Violet,
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '-0.12px',
      textTransform: 'uppercase',

      [breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  burgerCircle: {
    width: '40px',
    height: '40px',

    border: '1px solid #fff',
    padding: '15px 12px',
    borderRadius: '50px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',

    cursor: 'pointer',

    [breakpoints.down('xs')]: {
      padding: '11px 9px',
    },
  },
  bgViolet: {
    backgroundColor: Colors.Violet,
  },
  burger: {
    width: '14px',
    height: '10px',
    position: 'relative',
    margin: '0px auto',
    transform: 'rotate(0deg)',
    transition: '.5s ease-in-out',
    cursor: 'pointer',

    '& span': {
      display: 'block',
      position: 'absolute',
      height: '1.5px',
      width: '14px',
      background: '#fff',
      borderRadius: '9px',
      opacity: 1,
      left: '0',
      transform: 'rotate(0deg)',
      transition: '.25s ease-in-out',
    },

    '& span:nth-child(1)': { top: '0px', transformOrigin: 'left center' },
    '& span:nth-child(2)': { top: '5px', transformOrigin: 'left center' },
    '& span:nth-child(3)': { top: '10px', transformOrigin: 'left center' },

    '&.open span:nth-child(1)': {
      transform: 'rotate(45deg)',
      top: '0px',
      left: '2px',
    },
    '&.open span:nth-child(2)': { width: '0%', opacity: 0 },
    '&.open span:nth-child(3)': {
      transform: 'rotate(-45deg)',
      top: '10px',
      left: '2px',
    },
  },
})

const BurgerCrossIcon = ({ onClick, isOpen, classes, id, color, isMenuIcon }) => {
  const spans = [1, 2, 3].map(i => <span key={i} style={{ backgroundColor: color }}></span>)

  return isMenuIcon ? (
    <div id={id} className={classes.menuIcon} align="center" onClick={onClick}>
      <div
        style={{
          height: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={classNames(classes.burgerCircle, isMenuIcon && classes.bgViolet)}>
          <div className={classNames(classes.burger, isOpen && 'open')}>{spans}</div>
        </div>
      </div>
      <p>Меню</p>
    </div>
  ) : (
    <div
      className={classNames(classes.burgerCircle, isMenuIcon && classes.bgViolet)}
      onClick={onClick}
      style={{ borderColor: color }}
    >
      <div className={classNames(classes.burger, isOpen && 'open')}>{spans}</div>
    </div>
  )
}

BurgerCrossIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  isMenuIcon: PropTypes.bool,
}

export default withStyles(styles)(BurgerCrossIcon)

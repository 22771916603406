import React, { useEffect, useState } from 'react'
import Colors from '~shared/assets/styles/colors'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import withWidth from '@material-ui/core/withWidth'
import { withApi } from '~shared/api/ApiContext'
import arrowRight from '~shared/assets/img/icons/arrows/arrow_right.svg'
import arrowRightTop from '~shared/assets/img/icons/arrows/arrow_right_top.svg'
import arrowTop from '~shared/assets/img/icons/arrows/arrow_top.svg'
import arrowBottom from '~shared/assets/img/icons/arrows/arrow_bottom.svg'
import * as PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { ReactPortal } from '~src/new/shared/ui/ReactPortal/ReactPortal'

const arrows = {
  right: arrowRight,
  rightTop: arrowRightTop,
  top: arrowTop,
  bottom: arrowBottom,
}

// относительная позиция указывается следующим образом (значение = отступ в пикселях):
// t - top элемента top блока онбординга
// tb - top элемента bottom блока онбординга
// l - left элемента left блока онбординга
// lr - left элемента right блока онбординга
// r - right элемента right блока онбординга
// rl - right элемента left блока онбординга
// b - bottom элемента bottom блока онбординга
// bt - bottom элемента top блока онбординга

const styles = ({ breakpoints }) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100%',

    overflow: 'hidden',
    zIndex: 10000,
  },
  block: {
    position: 'absolute',
    padding: '20px 40px 30px',
    boxSizing: 'border-box',
    borderRadius: '16px',

    backgroundColor: Colors.White,
    boxShadow: '0px 30px 200px 0px rgba(0, 0, 0, 0.30)',
    zIndex: 10000,

    display: 'block',

    maxWidth: '444px',

    [breakpoints.down('sm')]: {
      width: 'calc(100% - 40px)',
      padding: '20px 35px 30px',
      maxWidth: 'none',
    },
  },

  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [breakpoints.down('sm')]: {
      width: 'calc(100% - 40px)',
      maxWidth: 'none',
    },
  },
  blockNumber: {
    color: '#9A9A9A',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: '15px',
  },
  title: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: '35px',
    letterSpacing: '-0.16px',

    [breakpoints.down('sm')]: {
      marginBottom: '25px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  button: {
    fontFamily: 'Cambay, sans-serif',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',

    textTransform: 'uppercase',

    color: Colors.Brand,

    cursor: 'pointer',
  },
  arrow: {
    // position: 'absolute',
  },
  overflow: {
    overflow: 'hidden !important',
  },
})

const greaterThanZero = value => (value > 0 ? value : 0)

const waitElement = (elementId, callback, startPosition = { t: 0, h: 0 }) => {
  const element = document.getElementById(elementId)
  if (element) {
    const header = document.getElementById('header')
    const top = element.offsetTop
    const height = element.offsetHeight
    if (top !== startPosition.t || height !== startPosition.h) {
      return setTimeout(() => {
        waitElement(elementId, callback, { t: top, h: height })
      }, 300)
    }
    const headerHeight = header?.offsetHeight || 0
    let scrollY = greaterThanZero(element.offsetTop - headerHeight - 100)

    if (scrollY > document.documentElement.scrollHeight - document.documentElement.clientHeight) {
      scrollY = document.documentElement.scrollHeight - document.documentElement.clientHeight
    }

    window.scrollTo(0, scrollY)
    callback()
  } else {
    setTimeout(() => {
      waitElement(elementId, callback)
    }, 500)
  }
}

const _isCorrectPage = paths => {
  return (
    !paths ||
    (paths && paths.includes(window.location.pathname)) ||
    (window.location.hash && paths.includes(window.location.hash))
  )
}

const Onboarding = ({ data, width, classes, api, location, onFinish }) => {
  const getActiveBlockPosition = index => {
    if (typeof data.blocks[index].position[width] === 'string') {
      const sizeRef = data.blocks[index].position[width]
      return data.blocks[index].position[sizeRef]
    }
    return data.blocks[index].position[width]
  }

  const [layout, setLayout] = useState(data.blocks)
  const [activeBlock, setActiveBlock] = useState(data.blocks[0])
  const [activeBlockIndex, setActiveBlockIndex] = useState(0)
  const [activeBlockPosition, setActiveBlockPosition] = useState(getActiveBlockPosition(0))
  const [elementPosition, setElementPosition] = useState(null)
  const [isCorrectPage, setIsCorrectPage] = useState(_isCorrectPage(data?.paths))

  useEffect(() => {
    setIsCorrectPage(_isCorrectPage(data?.paths))
  }, [data, location.pathname])

  useEffect(() => {
    setLayout(data.blocks)
    setActiveBlock(data.blocks[0])
    setActiveBlockIndex(0)
    setActiveBlockPosition(getActiveBlockPosition(0))
  }, [data])

  useEffect(() => {
    if (isCorrectPage && activeBlockPosition)
      waitElement(activeBlockPosition.elementId, setElementPositionHandler)
  }, [activeBlockIndex, activeBlockPosition, isCorrectPage])

  useEffect(() => {
    const body = document.querySelector('body')
    if (layout && isCorrectPage && elementPosition != null && body) {
      body.style.overflow = 'hidden'
      body.classList.add(classes.overflow)
    }
    return () => {
      if (!body) return
      body.style.removeProperty('overflow')
      body.classList.remove(classes.overflow)
    }
  }, [layout, data.paths, elementPosition, isCorrectPage])

  const setElementPositionHandler = () => {
    const el = document.getElementById(activeBlockPosition.elementId)
    const element = el.getBoundingClientRect()

    const elementPosition = {
      t: element.y,
      l: element.x,
      r: element.x + element.width,
      b: element.y + element.height,
    }

    setElementPosition(elementPosition)
  }

  const handleBlockClick = isLast => () => {
    if (isLast) {
      // close onboarding
      api.updateOnboardingSeen(data.id)
      onFinish()
    } else {
      setActiveBlockIndex(activeBlockIndex + 1)
      setActiveBlock(layout[activeBlockIndex + 1])
      if (typeof layout[activeBlockIndex + 1].position[width] === 'string') {
        const sizeRef = layout[activeBlockIndex + 1].position[width]
        setActiveBlockPosition(layout[activeBlockIndex + 1].position[sizeRef])
      } else {
        setActiveBlockPosition(layout[activeBlockIndex + 1].position[width])
      }
      setElementPosition(null)
    }
  }

  const positionCalc = (key, elementPosition, value) => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    switch (key) {
      case 't':
        return { value: elementPosition.t + value, styleKey: 'top' }
      case 'bt':
        return { value: elementPosition.b + value, styleKey: 'top' }
      case 'l':
        return { value: elementPosition.l + value, styleKey: 'left' }
      case 'lr':
        return { value: windowWidth - elementPosition.l + value, styleKey: 'right' }
      case 'r':
        return { value: windowWidth - elementPosition.r + value, styleKey: 'right' }
      case 'rl':
        return { value: windowWidth - elementPosition.r + value, styleKey: 'left' }
      case 'b':
        return { value: elementPosition.b + value, styleKey: 'bottom' }
      case 'tb':
        return { value: windowHeight - elementPosition.t + value, styleKey: 'bottom' }
    }
  }

  const renderBlock = (block, index) => {
    const length = layout.length

    const isLast = index === length - 1

    const style = {}

    if (activeBlockPosition.absolute) {
      const absolute = activeBlockPosition.absolute

      if (absolute.t) style.top = absolute.t
      if (absolute.l) style.left = absolute.l
      if (absolute.r) style.right = absolute.r
      if (absolute.b) style.bottom = absolute.b

      if (absolute.w) style.width = absolute.w
      if (absolute.h) style.height = absolute.h
    }

    if (activeBlockPosition.relative && elementPosition) {
      const relative = activeBlockPosition.relative

      Object.entries(relative).forEach(([key, value]) => {
        const { value: calcValue, styleKey } = positionCalc(key, elementPosition, value)

        style[styleKey] = greaterThanZero(calcValue)
      })

      if (relative.w) style.width = relative.w
      if (relative.h) style.height = relative.h
    }

    const arrowDirection = activeBlockPosition.arrowDirection || 'arrowDown'

    const isFlex =
      arrowDirection === 'right' || arrowDirection === 'left' || arrowDirection === 'rightTop'

    if (arrowDirection === 'rightTop') {
      style.justifyContent = 'flex-start'
    }

    if (arrowDirection === 'top') {
      style.display = 'flex'
      style.flexDirection = 'column-reverse'
    }

    const arrowStyle = activeBlockPosition.arrowStyle ?? {}

    return (
      <div
        key={index}
        className={classNames(classes.block, isFlex && classes.flex)}
        style={style}
        onClick={handleBlockClick(isLast)}
      >
        <div>
          <div className={classes.blockNumber}>
            {index + 1} из {length}
          </div>
          <div className={classes.title}>{block.title}</div>

          <div className={classes.button} id={`tip_button_${index}`}>
            {isLast ? 'Понятно,спасибо' : 'Следующий'}
          </div>
        </div>
        <div
          className={classes.arrow}
          style={{
            marginLeft: isFlex ? '40px' : 0,
            marginTop: arrowDirection === 'bottom' ? '25px' : 0,
            alignSelf:
              arrowDirection === 'top'
                ? window.screen.width > 1440
                  ? 'center'
                  : 'flex-end'
                : undefined,
            ...arrowStyle,
          }}
        >
          <img src={arrows[arrowDirection]} alt="arrow" />
        </div>
      </div>
    )
  }

  if (!layout) return null
  if (!isCorrectPage) return null
  if (elementPosition == null) return null

  return (
    <ReactPortal>
      <div className={classes.root}>{renderBlock(activeBlock, activeBlockIndex)}</div>
    </ReactPortal>
  )
}

Onboarding.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default withWidth()(withApi(withStyles(styles)(withRouter(Onboarding))))

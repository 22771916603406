import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography/Typography'
import classNames from 'classnames'
import IntlUtil from '~shared/utils/IntlUtil'
import Colors from '~shared/assets/styles/colors'
import HeaderItemTemplate from '~components/molecules/HeaderItem/template'
import { withWebSocket } from '~src/WebSocketContext'

const styles = theme => ({
  root: {},
  points: {
    ...theme.typography.numeric,
    fontWeight: 'bold',
    transition: `all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
  },
  pointsNormal: {
    transform: `scale(1.0, 1.0)`,
  },
  pointsLarge: {
    transform: `scale(1.3, 1.3) rotate(5deg)`,
  },
})

class HeaderItemCash extends React.Component {
  constructor(props) {
    super(props)

    const { member: { cashBalance = 0 } = {} } = props

    this.state = { cashBalance }
  }

  onUpdateBalance = cashBalance => {
    if (cashBalance !== this.state.cashBalance) this.setState({ cashBalance, large: true })
  }

  componentDidMount() {
    const { socket } = this.props

    socket && socket.on('updateCashBalance', this.onUpdateBalance)
  }

  componentWillUnmount() {
    const { socket } = this.props

    socket && socket.off('updateCashBalance', this.onUpdateBalance)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.large) {
      setTimeout(() => {
        this.setState({ large: null })
      }, 100)
    }
  }

  render() {
    const { classes, ...props } = this.props
    const { cashBalance, large } = this.state

    return (
      <HeaderItemTemplate {...props}>
        <Typography
          variant="h6"
          className={classNames(classes.points, {
            [classes.pointsNormal]: !large,
            [classes.pointsLarge]: !!large,
          })}
          style={{ color: Colors.GrassGreen }}
        >
          {IntlUtil.price(cashBalance)}
        </Typography>
      </HeaderItemTemplate>
    )
  }
}

export default withWebSocket(withStyles(styles)(HeaderItemCash))

import { useContext, useMemo } from 'react'
import { WidthContext } from './WidthContext'

// see sizes.scss
const BRAIKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

/**
 *
 * @return {{size: 'xs'|'sm'|'md'|'lg'|'xl', width: number, isMobile: boolean}}
 */
export const useWidth = () => {
  const width = useContext(WidthContext)

  const size = useMemo(() => {
    let size = 'xs'
    for (let key in BRAIKPOINTS) {
      if (width > BRAIKPOINTS[key]) {
        size = key
      }
    }
    return size
  }, [width])

  const isMobile = useMemo(() => size === 'xs' || size === 'sm', [size])

  return { width, size, isMobile }
}

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Colors from '~shared/assets/styles/colors'
import FooterItem from '~components/atoms/FooterItem'
import classNames from 'classnames'

const components = {
  FooterItem,
}

const styles = ({ breakpoints, spacing: { unit } }) => ({
  bar: {
    backgroundColor: Colors.MiddleGrey,
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    padding: '10px 0px',
    borderTop: '1px solid rgba(0, 0, 0, 0.05)',
    minHeight: 'auto',
  },
  width: {
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      // paddingTop: unit * 2,
      // paddingBottom: unit * 2,
    },
  },
  copyright: {
    margin: 0,

    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '-0.08px',
    fontWeight: 400,

    color: Colors.NewGrey,
    fontSize: '11px',
    lineHeight: '14px',

    [breakpoints.down('md')]: {
      marginRight: '45px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '8px',
      marginRight: '4px',
      whiteSpace: 'nowrap',
      '&:last-child': {
        marginBottom: 0,
      },
    },

    '& > a': {
      color: Colors.NewGrey,
      textDecoration: 'underline',
    },
  },
  copyrightCaptcha: {
    [breakpoints.down('sm')]: {
      fontSize: '8px',
      whiteSpace: 'pre-wrap',
      textAlign: 'start',
    },
  },
  rightItem: {
    color: Colors.White,
    [breakpoints.down('xs')]: {
      '&:last-child': {
        marginBottom: unit,
      },
    },
  },
  content: {
    // flexWrap: 'wrap',
    flexBasis: 'auto', // ie 11
    width: '100%',
    // maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '0 10px',

    [breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      padding: '0px 5px',
      boxSizing: 'border-box',
    },
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },

  paddingBottomXs: {
    [breakpoints.down('xs')]: {
      paddingBottom: '85px',
    },
  },
})

const Footer = withStyles(styles)(
  class Footer extends React.Component {
    render() {
      const {
        classes,
        className,
        data: { items = [] },
        captchaBrandingVisibility,
        isDashboard,
      } = this.props
      return (
        <Toolbar
          disableGutters
          className={classNames(className, classes.bar, { [classes.paddingBottomXs]: isDashboard })}
        >
          <div className={classes.width}>
            <div className={classes.container}>
              <div className={classes.content}>
                <p className={classes.copyright}>© Copyright JT International</p>
                <p className={classes.copyright}>Сайт принадлежит ООО «Дж.Т.И. Россия».</p>
                {/*{captchaBrandingVisibility && (*/}
                <p className={classNames(classes.copyright, classes.copyrightCaptcha)}>
                  This site is protected by reCAPTCHA and the Google.{' '}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  apply.
                </p>
                {/*)}*/}
                <p className={classes.copyright}>
                  123112, Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32
                </p>
                <p className={classes.copyright}>
                  <a href="mailto:info@jti-partner.ru">info@jti-partner.ru</a>
                </p>
              </div>
              {/*<div item xs={6} lg={0} className={classes.content}>*/}
              {/*  {items.map(({ props, component }, index) => {*/}
              {/*    const ItemComponent = components[component]*/}
              {/*    return (*/}
              {/*      <ItemComponent key={'item_' + index} {...props} className={classes.rightItem} />*/}
              {/*    )*/}
              {/*  })}*/}
              {/*</div>*/}
            </div>
          </div>
        </Toolbar>
      )
    }
  },
)

export default Footer

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    zIndex: 9998,
    width: '100%',
    height: 5,
  },
}

export default withStyles(styles)(({ color, classes }) => {
  return <div className={classes.root} style={{ backgroundColor: color }} />
})

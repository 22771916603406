import React from 'react'

const WebSocketContext = React.createContext(null)

const withWebSocket = Component => props => {
  return (
    <WebSocketContext.Consumer>
      {({ socket }) => <Component {...props} socket={socket} />}
    </WebSocketContext.Consumer>
  )
}

export default WebSocketContext
export { withWebSocket }

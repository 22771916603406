import React from 'react'
import Icon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import HeaderItemTemplate from './template'
import accountIcon from '~shared/assets/img/icons/profile_icon.svg'
import notificationsIcon from '~shared/assets/img/icons/notif_icon.svg'

const styles = ({ spacing: { unit } }) => ({
  image: {
    width: unit * 2,
    height: unit * 2,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
})

const iconToComponent = icon => {
  let component = null
  switch (icon) {
    case 'account_circle':
      component = <img src={accountIcon} alt="account icon" />
      break
    case 'notifications':
      component = <img src={notificationsIcon} alt="notifications icon" />
      break
    default:
      component = <Icon color={'primary'}>{icon}</Icon>
  }
  return component
}

const HeaderItem = withStyles(styles)(({ icon, classes, ...props }) => (
  <HeaderItemTemplate {...props}>
    {icon && icon.indexOf('/') === 0 ? (
      <div className={classes.image} style={{ backgroundImage: `url("${props.icon}")` }} />
    ) : (
      iconToComponent(icon)
    )}
  </HeaderItemTemplate>
))

export default HeaderItem

import React from 'react'

const resetLineHeight = `* {
  line-height: normal !important;
}`

const resetLetterSpacing = `* {
  letter-spacing: 0 !important;
}`

const fixesByLanguage = {
  km: [resetLineHeight, resetLetterSpacing],
}

export default function IntlStyleFixes({ language }) {
  const fixes = fixesByLanguage[language]

  if (fixes && fixes.length) {
    return fixes.map((fix, i) => <style key={i.toString()}>{fix}</style>)
  }

  return null
}

import React from 'react'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import { CustomSnackBarContent } from '~shared/components/atoms/CustomSnackBar'
import { withApi } from '~shared/api/ApiContext'

class SnackMessages extends React.Component {
  componentDidMount() {
    const { api: { connector } = {} } = this.props
    connector && (this.errorSubscription = connector.listenToAlerts(this.onAlert))
  }

  componentWillUnmount() {
    this.errorSubscription && this.errorSubscription.unsubscribe()
  }

  onAlert = alert => {
    const { enqueueSnackbar } = this.props
    const {
      message,
      stack,
      variant,
      dontHide = false,
      preventDuplicate = false,
      customAction,
    } = alert

    if (message === undefined) {
      return
    }
    // This function call is the one that is in charge of creating the snacks
    enqueueSnackbar('', {
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 6000,
      persist: dontHide,
      preventDuplicate,
      children: key => (
        <CustomSnackBarContent
          onClose={() => this.handleClose2(key)}
          message={message + (stack ? ': ' + stack : '')}
          variant={variant}
          customActions={
            customAction && [
              <Button
                color="secondary"
                style={{ height: '100%', width: '100%', position: 'absolute', left: 0, top: 0 }}
                onClick={() => {
                  window.navigateTo(customAction)
                  this.handleClose2(key)
                }}
              />,
            ]
          }
        />
      ),
    })
  }

  handleClose2 = key => {
    const { closeSnackbar } = this.props
    closeSnackbar(key)
  }

  render() {
    return <div></div>
  }
}

export default withSnackbar(withApi(SnackMessages))

import React, { useEffect, useMemo, useState } from 'react'
import { HistoryStoreContext } from '../lib/HistoryStoreContext'

const EXCLUDED_PAGES = ['/login', '/signup']

try {
  JSON.parse(window.localStorage.getItem('historyFullPages'))
} catch (e) {
  window.localStorage.setItem('historyFullPages', '[]')
}

const defaultStore = {
  previousFullPage: window.localStorage.getItem('previousFullPage') || null,
  currentFullPage: window.localStorage.getItem('currentFullPage') || null,
  historyFullPages: JSON.parse(window.localStorage.getItem('historyFullPages') || '[]'),
}

/**
 *
 * @param {Object} props
 * @param {Object} props.children
 * @param {Object} props.location
 * @returns
 */
export const HistoryStoreProvider = ({ children, location }) => {
  const [historyStore, setHistoryStore] = useState(defaultStore)

  useEffect(() => {
    if (location.hash && location.hash !== '#' && !location?.hash?.includes('page')) return
    if (EXCLUDED_PAGES.includes(location.path)) return
    const currentFullPage = location.pathname + location.search
    if (currentFullPage === historyStore.historyFullPages[0]) return

    if (location.pathname === historyStore.historyFullPages[0] && location.search === '?page=1')
      historyStore.historyFullPages?.shift()

    const historyFullPages = [currentFullPage, ...historyStore.historyFullPages]

    if (historyStore.historyFullPages.length > 5) {
      historyFullPages.splice(5)
    }

    window.localStorage.setItem('historyFullPages', JSON.stringify(historyFullPages))
    window.localStorage.setItem('previousFullPage', historyStore.currentFullPage)
    window.localStorage.setItem('currentFullPage', location.pathname)

    setHistoryStore(prev => ({
      ...prev,
      previousFullPage: historyStore.currentFullPage,
      currentFullPage,
      historyFullPages,
    }))
  }, [location])

  const value = useMemo(
    () => ({
      historyStore,
      setHistoryStore,
    }),
    [historyStore, setHistoryStore],
  )

  return <HistoryStoreContext.Provider value={value}>{children}</HistoryStoreContext.Provider>
}

import React from 'react'
import { Link } from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Colors from '~shared/assets/styles/colors'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  button: {
    // paddingLeft: unit - 2,
    // paddingRight: unit - 2,
    cursor: 'pointer',
    borderRadius: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'red',
  },
  xsOptional: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  smOptional: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    [breakpoints.up('md')]: {
      marginTop: unit * 0.5,
    },
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0px',
    right: '-15%',

    height: '16px',
    width: '16px',
    borderRadius: '50%',
    border: '1.5px solid #ffffff',
    backgroundColor: '#E03C31',

    [breakpoints.down('sm')]: {
      top: '5px',
    },

    '& > p': {
      margin: 0,
      color: '#ffffff',

      fontFamily: 'Roboto',
      fontSize: '9px',
      fontWeight: '700',
    },
  },
  caption: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.01em',

    textTransform: 'uppercase',
    color: Colors.Black,
  },
  disabled: {
    opacity: 0.2,
  },
})

const HeaderItemTemplate = withStyles(styles)(
  ({
    className,
    label,
    buttonId,
    classes,
    xsIconVisible,
    onClick,
    to,
    target,
    children,
    _link = {},
    hasNotifications,
    labelColor,
    selectedAccountIsArchive,
  }) => {
    to = _link.delegateTo ? _link.delegateTo : to
    target = _link.delegateTo ? '_blank' : target
    const component = to && target !== '_blank' ? Link : _link.delegateTo ? 'a' : undefined

    const isDisabledItem = to === '#notification_center' && selectedAccountIsArchive

    return (
      <ButtonBase
        TouchRippleProps={{ style: { color: Colors.CoolGrey } }}
        onClick={onClick}
        component={component}
        disabled={isDisabledItem}
        id={buttonId}
        target={target}
        href={to}
        to={to}
        className={`${className || ''} ${classes.button} ${
          !xsIconVisible ? classes.xsOptional : ''
        } ${isDisabledItem ? classes.disabled : ''}`}
      >
        <div className={classes.content}>
          {children}
          {!isDisabledItem && hasNotifications ? (
            <div className={classes.counter}>
              <p>{hasNotifications}</p>
            </div>
          ) : null}
        </div>
        {label && (
          <Typography
            variant={'caption'}
            className={`${classes.caption} ${classes.smOptional}`}
            style={{ color: labelColor }}
          >
            {label}
          </Typography>
        )}
      </ButtonBase>
    )
  },
)

export default HeaderItemTemplate

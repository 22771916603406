import React from 'react'
import Grid from '@material-ui/core/Grid'

const GridLines = ({ maxWidth = 1280, position = 'absolute' }) => (
  <Grid
    container
    justify={'space-between'}
    direction={'row'}
    style={{
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      top: 0,
      width: '100%',
      maxWidth,
      position,
      pointerEvents: 'none',
      zIndex: 2222,
      opacity: 0.05,
      height: '100%',
    }}
  >
    {new Array(13).fill(<div style={{ backgroundColor: 'red', width: 24, height: '100%' }} />)}
  </Grid>
)

export default GridLines

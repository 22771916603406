function withOpacity(color, opacity) {
  const r = parseInt(color.slice(1, 3), 16),
    g = parseInt(color.slice(3, 5), 16),
    b = parseInt(color.slice(5, 7), 16)

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')'
}

const Colors = {
  // DEFAULT COLORS
  White: '#ffffff',
  Black: '#000000',
  Grey: '#122632',
  LightGrey: '#f1f1f1',
  MiddleGrey: '#F2F2F2',
  BorderGrey: '#e0e0e0',
  CoolGrey: '#889298',
  NewGrey: '#979797',
  DarkGrey: '#4d5c65',
  DarkGrey2: '#333333',
  GrayBG: '#EBEBEB',
  GREY: '#9A9A9A',
  Red: '#e03c31',
  PaleRed: '#e03c31',
  Orange: '#FDA609',
  Yellow: '#eaaa00',
  LightYellow: '#ffface',
  LightBlue: '#4ba7e8',
  // CORPORATE BRAND COLORS
  Aqua: '#00b2a9',
  Fuschia: '#e782a9',
  Violet: '#8031a7',
  Blue: '#407ec9',
  CorporateGreen: '#18ad84',
  Brand: '#00b2a9',
  TextBrand: '#008476',
  GrassGreen: '#b0d121',
  // Gradients
  spectrum1:
    'linear-gradient(90deg, rgba(77,185,100,1) 0%, rgba(231,179,80,1) 49%, rgba(236,148,157,1) 100%)',
  spectrum2:
    'linear-gradient(90deg, rgba(226,83,101,1) 0%, rgba(236,108,72,1) 49%, rgba(209,144,172,1) 100%)',
  spectrum3:
    'linear-gradient(90deg, rgba(189,173,215,1) 0%, rgba(135,81,159,1) 49%, rgba(177,127,157,1) 100%)',
  spectrum4:
    'linear-gradient(90deg, rgba(219,148,159,1) 0%, rgba(205,176,156,1) 49%, rgba(38,176,180,1) 100%)',
  spectrum5:
    'linear-gradient(270deg, rgba(99,179,93,1) 0%, rgba(85,187,176,1) 49%, rgba(212,170,154,1) 100%)',
  spectrum6: 'linear-gradient(90deg, #FFC8BA 0%, #BCB3FD 50%, #CA3E71 100%)',
  spectrum7: 'linear-gradient(90deg, #C2D5ED 0%, #37B3E5 50%, #000046 100%)',
  spectrum8: 'linear-gradient(90deg, #FFB548 0%, #730E12 50%, #340F12 100%)',
  spectrum9: 'linear-gradient(90deg, #B2DBD4 0%, #00B189 50%, #00303C 100%)',
  dark: '#2D2926',
  white: '#FFF',

  gradientBackground: `linear-gradient(145deg, rgba(207,174,168,0.3) 16%,
    rgba(139,188,188,0.3) 45%,
    rgba(100,193,195,0.3) 60%,
    rgba(73,184,161,0.3) 80%,
    rgba(80,178,104,0.3) 98%)`,
}

Colors['White012'] = withOpacity(Colors.White, 0.12)
Colors['White040'] = withOpacity(Colors.White, 0.4)
Colors['White060'] = withOpacity(Colors.White, 0.6)
Colors['White080'] = withOpacity(Colors.White, 0.8)
Colors['White095'] = withOpacity(Colors.White, 0.95)
Colors['Black080'] = withOpacity(Colors.Black, 0.8)
Colors['Black06'] = withOpacity(Colors.Black, 0.6)
Colors['Black038'] = withOpacity(Colors.Black, 0.38)
Colors['Black012'] = withOpacity(Colors.Black, 0.12)
Colors['Black005'] = withOpacity(Colors.Black, 0.05)
Colors['Brand02'] = withOpacity(Colors.Brand, 0.2)
Colors['Brand05'] = withOpacity(Colors.Brand, 0.5)
Colors['Grey0'] = withOpacity(Colors.Grey, 0)
Colors['Grey008'] = withOpacity(Colors.Grey, 0.08)
Colors['Grey024'] = withOpacity(Colors.Grey, 0.24)
Colors['Grey04'] = withOpacity(Colors.Grey, 0.4)
Colors['Grey06'] = withOpacity(Colors.Grey, 0.6)
Colors['Grey08'] = withOpacity(Colors.Grey, 0.8)
Colors['DarkGrey16'] = withOpacity(Colors.DarkGrey2, 0.16)
Colors['Aqua010'] = withOpacity(Colors.Aqua, 0.1)
Colors['Aqua020'] = withOpacity(Colors.Aqua, 0.2)
Colors['Aqua060'] = withOpacity(Colors.Aqua, 0.6)
Colors['Aqua080'] = withOpacity(Colors.Aqua, 0.8)
Colors['Aqua05'] = withOpacity(Colors.Aqua, 0.5)
export default Colors

export { withOpacity }

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography/Typography'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Button from '@material-ui/core/Button/Button'
import ButtonIconLeft from '~components/atoms/ButtonIconLeft'
import { withApi } from '~shared/api/ApiContext'

const styles = ({ breakpoints, spacing: { unit }, palette }) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    height: unit * 4.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginLeft: unit * 1.5,
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    '& $selectContainer + *': {
      marginLeft: unit * 2,
    },
  },
  selectContainer: {
    '& label': {
      fontSize: '0.8rem',
    },
    '& label + *': {
      marginLeft: unit * 0.5,
    },
  },
  select: {
    minWidth: unit * 10,
    maxWidth: unit * 16.7,
    [breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  button: {
    height: unit * 4.5,
    boxShadow: 'none',
    color: palette.primary.main,
  },
})

class AppMode extends React.Component {
  constructor(props) {
    super(props)
    const multiselectActions = props.actions.filter(action => action.type === 'multiselect')
    const initialState = multiselectActions.reduce((acc, action) => {
      acc[action.label] = { open: false, value: action.value }
      return acc
    }, {})

    this.state = initialState
  }

  render() {
    const { classes, title, color, actions } = this.props

    return (
      <div className={classes.root} style={{ backgroundColor: fade(color, 0.7) }}>
        <Typography className={classes.title}>{title}</Typography>

        <div className={classes.buttonContainer}>{actions.map(this.renderAction)}</div>
      </div>
    )
  }

  renderAction = (action, index) => {
    const { classes, color } = this.props
    const { type, label, value, options, icon } = action
    const actionState = this.state[action.label]

    switch (type) {
      case 'select': {
        return (
          <div className={classes.selectContainer} key={index}>
            <InputLabel>{label}</InputLabel>
            <Select
              className={classes.select}
              displayEmpty
              value={value}
              onChange={this.handleAction(action)}
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
        )
      }
      case 'multiselect': {
        return (
          <div className={classes.selectContainer} key={index}>
            <InputLabel>{label}</InputLabel>
            <Select
              open={actionState.open}
              className={classes.select}
              multiple={type === 'multiselect'}
              value={actionState.value}
              onChange={this.handleMultiSelectChange(action)}
              onClose={this.handleClose(action)}
              onOpen={this.handleOpen(action)}
            >
              {options.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
        )
      }
      case 'button': {
        return (
          <Button
            style={{ backgroundColor: color }}
            className={classes.button}
            variant="contained"
            onClick={this.handleAction(action)}
            key={index}
          >
            {icon && <ButtonIconLeft>{icon}</ButtonIconLeft>}
            {label}
          </Button>
        )
      }
      default:
    }
  }

  handleOpen = action => e => {
    const { state } = this
    const actionState = { ...state[action.label] }
    actionState.open = true

    this.setState({ ...state, [action.label]: actionState })
  }

  handleClose = action => e => {
    const { state } = this
    const actionState = { ...state[action.label] }
    actionState.open = false
    const { api } = this.props
    const { method = 'POST', url, type } = action

    let data = {}

    if (type === 'select' || type === 'multiselect') data.value = actionState.value

    api.connector[method.toLowerCase()](url, data)

    this.setState({ ...state, [action.label]: actionState })
  }

  handleMultiSelectChange = action => e => {
    const { state } = this
    const actionState = { ...state[action.label] }
    actionState.value = e.target.value

    this.setState({ ...state, [action.label]: actionState })
  }

  handleAction = action => e => {
    const { api } = this.props
    const {
      target: { value },
    } = e
    const { method = 'POST', url, type } = action

    let data = {}

    if (type === 'select' || type === 'multiselect') data.value = value

    api.connector[method.toLowerCase()](url, data)
  }
}

export default withApi(withStyles(styles)(AppMode))

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Colors from '~shared/assets/styles/colors'
import sizeImagePath from '~shared/utils/sizeImagePath'

const styles = ({ spacing: { unit } }) => ({
  padded: {
    padding: unit * 2,
    boxSizing: 'border-box',
  },
  cover: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  contain: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  fallbackImage: {
    width: '100%',
    height: '100%',
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
    '& > *:first-child': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  zoomEffectContainer: {
    '& > *:first-child': {
      transform: 'scale(1.01,1.01)', // fix for a rendering bug
      transition: 'transform 250ms ease-out',
    },
    '&:hover': {
      '& > *:first-child': {
        transition: 'transform 330ms ease-in-out',
        transform: 'scale(1.1,1.1)',
      },
    },
  },
  hovered: {
    '& > *:first-child': {
      transition: 'transform 330ms ease-in-out',
      transform: 'scale(1.1,1.1)',
    },
  },
  paddedAbsolute: {
    '& > *:first-child': {
      position: 'absolute',
      left: unit * 2,
      right: unit * 2,
      top: unit * 2,
      bottom: unit * 2,
    },
  },
})

// const PRELOAD_BG_URL = '/tmp/brands.gif'

const ImageFallback = ({ className, style = {}, minHeight = 100 }) => (
  <svg className={className} style={{ minHeight, ...style }}>
    <rect width="100%" height="100%" fill={Colors.Black012} stroke={Colors.White} />
    <line stroke={Colors.White} strokeWidth={0.5} x1="100%" y1="0" x2="0" y2="100%" />
  </svg>
)

class ImageBase extends React.PureComponent {
  state = {
    loaded: false,
    useFallback: false,
  }
  componentDidMount() {
    // const { isBackground } = this.state
    //
    // if (!isBackground) {
    //   return
    // }
    //TODO: It's not working properly
    // const preloadingImage = new Image()
    // preloadingImage.src = imagePath
    // preloadingImage.onload = this._handleLoad
    //
    // this.setState({
    //   loaded: true
    // })
  }

  render() {
    const {
      className,
      classes,
      style,
      hideFallbackImage,
      bgZoomOnHover,
      image,
      imageSize,
      trim,
      cover,
      contain,
    } = this.props
    const { error, useFallback } = this.state
    const imagePath = sizeImagePath(image, imageSize, trim)
    const isBackground = cover || contain

    if (error) {
      const hide = hideFallbackImage ? { visibility: 'hidden' } : ''

      return !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
        <ImageFallback
          className={classNames(classes.fallbackImage, className)}
          style={{ ...style, ...hide }}
        />
      ) : null
    }

    let path = imagePath

    if (useFallback) path = this.props.fallbackImage

    //TODO: It's not working properly
    // if(!loaded && isBackground) {
    //   path = PRELOAD_BG_URL
    // }

    return isBackground
      ? bgZoomOnHover
        ? this._renderAsBackGroundInSeparateDiv(path, this.props)
        : this._renderAsBackGround(path, this.props)
      : this._renderAsRegularImage(path, this.props)
  }

  _renderAsRegularImage = (
    path,
    { className, classes, style, noGutters, slideSafe, alt, height },
  ) => (
    <img
      onError={this._handleError}
      className={classNames({ [classes.padded]: noGutters !== true }, className)}
      style={{ ...style, ...(slideSafe && { pointerEvents: 'none' }) }}
      src={path}
      alt={alt || ''}
      height={height}
    />
  )

  _renderAsBackGround = (path, { className, classes, style, cover, children }) => (
    <div
      onError={this._handleError}
      className={classNames(cover ? classes.cover : classes.contain, className)}
      style={{ backgroundImage: `url("${path}")`, ...style }}
    >
      {children}
    </div>
  )

  _renderAsBackGroundInSeparateDiv = (
    path,
    { className, classes, style, cover, children, forceHover, noGutters = true, disabled },
  ) => {
    return (
      <div
        className={classNames(
          classes.container,
          {
            [classes.zoomEffectContainer]: !disabled,
            [classes.paddedAbsolute]: !noGutters,
            [classes.hovered]: forceHover && !disabled,
          },
          className,
        )}
        style={style}
      >
        <div
          onError={this._handleError}
          className={cover ? classes.cover : classes.contain}
          style={{ backgroundImage: `url("${path}")` }}
        />
        {children}
      </div>
    )
  }

  _handleError = () => {
    if (this.props.fallbackImage && !this.state.useFallback) {
      this.setState({ useFallback: true })
      return
    }
    this.setState({ error: true })
  }
  _handleLoad = () => {
    this.setState({ loaded: true })
  }
}

ImageBase.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  image: PropTypes.string.isRequired,
  fallbackImage: PropTypes.string,
  alt: PropTypes.string,
  cover: PropTypes.bool,
  contain: PropTypes.bool,
  imageSize: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  trim: PropTypes.bool,
  slideSafe: PropTypes.bool,
  noGutters: PropTypes.bool,
  bgZoomOnHover: PropTypes.bool,
  height: PropTypes.string,
}

export default withStyles(styles)(ImageBase)
export { sizeImagePath }

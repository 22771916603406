const Constants = {
  drawerWidth: 445,
  activityTypeToColor: {
    games: 'linear-gradient(90deg, #BFABD8 0%, #BC8CCD 48.96%, #B371C4 74.48%, #9C42A6 100%)',
    mod_tasks: 'linear-gradient(90deg, #F1B253 0%, #EDA378 51.56%, #E7657F 97.40%)',
    tobaccoshoppers: 'linear-gradient(90deg, #8CD840 0%, #55A368 100%)',
    quizs: 'linear-gradient(90deg, #65C4ED 0%, #407EC9 100%)',
    surveys: 'linear-gradient(90deg, #E782A9 0%, #C9447B 100%)',
  },
  activityTypeToLocale: {
    games: 'Игра',
    mod_tasks: 'Фото-задание',
  },
}

export default Constants

import React, { useEffect, useMemo, useState } from 'react'
import { StoreContext } from '../lib/StoreContext'
import { AppFrameConsumer } from '~shared/components/AppFrameContext'

const defaultStore = {
  isFakePopupOpen: false,
}

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(defaultStore)

  const value = useMemo(
    () => ({
      store,
      setStore,
    }),
    [store, setStore],
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const withStoreContext = Component => props => {
  return (
    <StoreContext.Consumer>
      {data => <Component {...props} storeDataProps={data} />}
    </StoreContext.Consumer>
  )
}

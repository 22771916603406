import React from 'react'
import Header from '~components/organisms/Header'
import Footer from '~components/organisms/Footer'
import MainMenu from '~components/organisms/MainMenu'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageProgress from '~shared/components/PageProgress'
import GridLines from '~components/molecules/GridLines'
import EnvBar from '~shared/components/atoms/EnvBar'
import Colors from '~shared/assets/styles/colors'
import classNames from 'classnames'
import { AccountProvider } from '~src/AccountContext'
import { AppFrameProvider, withAppFrame } from '~shared/components/AppFrameContext'
import BurgerCrossIcon from '~components/molecules/BurgerCrossIcon/BurgerCrossIcon'
import Onboarding from '~components/organisms/Onboarding'
import withWidth from '@material-ui/core/withWidth'

const styles = ({}) => ({
  container: {
    position: 'relative',
    minHeight: '100vh',
    zIndex: 0,
    '@media print': {
      display: 'block',
    },
  },
  containerBGWhite: {
    backgroundColor: Colors.White,
  },
  content: {
    width: '100%',
    maxHeight: '100%',
    flexGrow: 1,
    '@media print': {
      backgroundColor: Colors.White,
    },
  },
  footer: {
    width: '100%',
  },
  mainMenu: {
    height: '100%',
  },

  fadeEnter: {
    opacity: 0,
    // transform: `translate3d(0, ${-unit * 2}px, 0)`,
    clipPath: 'circle(0px at center)',
  },
  fadeEnterActive: {
    opacity: 1,
    // transform: 'translate3d(0,0,0)',
    clipPath: 'circle(100% at center)',
    transition: `opacity 350ms 100ms, 
      clip-path 350ms ease-in`,
  },
  loader: {
    position: 'absolute',
    width: '100%',
    zIndex: 9999,
  },
})

const AppFrame = withStyles(styles)(
  withWidth()(
    class AppFrame extends React.Component {
      state = {
        onboardingIndex: null,
      }

      static getDerivedStateFromProps(props) {
        if (props.data) {
          const { header: { items = [] } = {} } = props.data
          let xsMenuItems = []
          items.forEach(item => {
            if (!item.props.xsIconVisible) {
              xsMenuItems = [...xsMenuItems, { ...item.props, _link: item._link }]
            }
          })
          return { data: props.data, xsMenuItems }
        }

        return {}
      }

      componentDidMount() {
        this._isMounted = true
        if (process.env.NODE_ENV === 'development') {
          window.addEventListener('keyup', this.onKeyUp)
        }
        window.addEventListener('resize', this.calcHeaderOffset)
      }

      componentWillUnmount() {
        this._isMounted = false
        window.removeEventListener('keyup', this.onKeyUp)
        window.removeEventListener('resize', this.calcHeaderOffset)
      }

      componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.member && this.props.member) {
          this.calcHeaderOffset()
        }
      }

      onKeyUp = e => {
        if (e.keyCode === 71 && e.altKey) {
          this.setState({ showGrid: !this.state.showGrid })
        }
      }

      onHeaderUnmount = () => {
        this.setState({ headerHightOffset: 0 })
      }

      onHeaderMount = () => {
        this.calcHeaderOffset()
      }

      // calculate the header position beacause it will be fixed, so we can move the content accordingly
      calcHeaderOffset = () => {
        if (!this._isMounted) return

        const header = document.getElementById('header')
        if (!header) return

        const headerHightOffset = header.offsetHeight

        this.setState({ headerHightOffset })
      }

      handleAccountChange = selectedAccountId => {
        const { api } = this.props
        api.changeSelectedAccount({ selectedAccountId })
      }

      render() {
        const {
          mode,
          children,
          classes,
          busy,
          routeKey,
          contentKey,
          member,
          api,
          history,
          isPrint,
          showLoadBar,
          componentName,
          width,
          storeDataProps: { store } = {},
        } = this.props
        const { isFakePopupOpen } = store
        const { accounts, selectedAccountId } = member || {}
        const {
          data,
          xsMenuItems,
          isMenuOpen,
          showGrid,
          headerHightOffset = 0,
          onboardingIndex,
        } = this.state

        if (!data) {
          return null
        }

        const {
          header,
          menu,
          apps,
          footer,
          onboarding = [],
          env: { color } = {},
          settings: { general, technical: { captchaVisibility } = {}, labels } = {},
        } = data
        const { displayHealthWarning, healthWarningDesktop, healthWarningMobile } = general || {}

        const selectedAccount = accounts?.find(({ _id }) => _id === selectedAccountId)

        const selectedAccountIsArchive = selectedAccount?.accountStatus !== 'active'

        let availableMenu = menu && [...menu]
        if (selectedAccountIsArchive && availableMenu) {
          const availableMenuItems = [
            '/profile',
            '/rewards',
            '/faqs',
            'integration_instructions',
            '/brands',
            '/products',
            '/contact',
            '/consumer-trials',
          ]
          availableMenu = availableMenu.filter(
            ({ to, icon }) => availableMenuItems.includes(to) || availableMenuItems.includes(icon),
          )
        }

        const isDashboard = componentName === 'Dashboard'
        // const isFakePopupPage = width === 'xs' && componentName === 'ActivitiesOverviewTemplate'
        const additionPadding = width === 'xs' ? 103 : 40

        const headerOffset =
          componentName !== 'LoginPageMobilephone' &&
          selectedAccountIsArchive &&
          headerHightOffset < 143
            ? headerHightOffset + additionPadding
            : headerHightOffset

        onboarding?.find((data, i) => {
          const show =
            !data.paths ||
            (data.paths &&
              (data.paths.includes(window.location.pathname) ||
                (window.location.hash && data.paths.includes(window.location.hash))))
          if (show && onboardingIndex !== i) this.setState({ onboardingIndex: i })
          return show
        })

        return (
          <AppFrameProvider value={data}>
            <AccountProvider
              value={{ accounts, selectedAccountId, onAccountChange: this.handleAccountChange }}
            >
              <Grid
                data-template-id={componentName}
                container
                className={classNames(classes.container, isPrint && classes.containerBGWhite)}
                direction={'column'}
              >
                {!isFakePopupOpen && !showLoadBar && !isPrint && color && <EnvBar color={color} />}

                {!isFakePopupOpen && (
                  <PageProgress visible={showLoadBar || busy} className={classes.loader} />
                )}

                {showGrid && <GridLines />}

                {!isPrint && header && !isFakePopupOpen && (
                  <Header
                    id="header"
                    member={member}
                    data={header}
                    labels={labels}
                    mode={mode}
                    history={history}
                    onMount={this.onHeaderMount}
                    onUnmount={this.onHeaderUnmount}
                    healthWarningDesktop={displayHealthWarning && healthWarningDesktop}
                    healthWarningMobile={displayHealthWarning && healthWarningMobile}
                    selectedAccountIsArchive={selectedAccountIsArchive}
                    contentComponentName={componentName}
                    renderDrawerIcon={() =>
                      availableMenu ? (
                        <BurgerCrossIcon
                          id={'mainpage_burger_menu'}
                          onClick={this._handleDrawerIconClick}
                          isOpen={isMenuOpen}
                          isMenuIcon={true}
                        />
                      ) : (
                        <div style={{ width: '60px' }} />
                      )
                    }
                  />
                )}

                <TransitionGroup
                  className={classes.content}
                  style={{
                    marginTop: headerOffset,
                  }}
                >
                  <CSSTransition
                    key={routeKey}
                    timeout={{ enter: 450, exit: 0 }}
                    style={{ height: '100%' }}
                    classNames={{
                      enter: classes.fadeEnter,
                      enterActive: classes.fadeEnterActive,
                    }}
                  >
                    <div key={contentKey}>{children}</div>
                  </CSSTransition>
                </TransitionGroup>

                {!isPrint && footer && (
                  <Footer
                    className={classes.footer}
                    data={footer}
                    captchaBrandingVisibility={captchaVisibility}
                    isDashboard={isDashboard}
                  />
                )}

                {!isPrint && availableMenu && (
                  <div className={classes.mainMenu}>
                    <MainMenu
                      open={isMenuOpen}
                      data={availableMenu}
                      apps={apps}
                      member={member}
                      xsMenuItems={xsMenuItems}
                      className={classes.mainMenu}
                      onOverlayClick={this._handleMenuOverlayClick}
                      api={api}
                      menuIcon={
                        <BurgerCrossIcon
                          onClick={this._handleDrawerIconClick}
                          isOpen={isMenuOpen}
                        />
                      }
                      headerHightOffset={headerHightOffset}
                    />
                  </div>
                )}
                {!isPrint && onboarding[onboardingIndex] !== undefined && (
                  <Onboarding
                    data={onboarding[onboardingIndex]}
                    onFinish={() => {
                      onboarding.splice(onboardingIndex, 1)
                      this.setState({ onboardingIndex: null })
                    }}
                  />
                )}
              </Grid>
            </AccountProvider>
          </AppFrameProvider>
        )
      }

      _handleDrawerIconClick = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen })
      }

      _handleMenuOverlayClick = () => {
        this.setState({ isMenuOpen: false })
      }
    },
  ),
)

export default AppFrame

export { withAppFrame }

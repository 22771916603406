/**
 * @typedef {Object} SearchResult
 * @property {string} _id
 * @property {string} title
 * @property {string} description
 * @property {string} content
 * @property {string} slug
 * @property {string} sectionName
 *
 */

import { useEffect, useState } from 'react'

/**
 *
 * @param {PortalApi} api
 * @returns {[SearchResult[], string, function(e:ChangeEvent):void, function(e:UIEvent):void]} searchResults
 *
 */
const useSearchResults = api => {
  const [searchString, setSearchString] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [searchChangeTimer, setSearchChangeTimer] = useState(null)
  const [page, setPage] = useState(1)
  const [scrollHeigthPageIsLoad, setScrollHeigthPageIsLoad] = useState([])

  useEffect(() => {
    sendRequest()
  }, [searchString])

  const sendSearchRequest = async (searchString, page) => {
    if (!searchString || searchString.length < 2) return setSearchResults(null)
    const { request } = api.getSearchResults(searchString, page)
    const response = await request
    if (response) {
      if (page === 1) setSearchResults(response)
      else setSearchResults([...searchResults, ...response])
    } else {
      setSearchResults([])
    }
  }

  const sendRequest = () => {
    if (searchChangeTimer) clearTimeout(searchChangeTimer)
    setSearchChangeTimer(
      setTimeout(() => {
        if (searchString) {
          sendSearchRequest(searchString, page).catch(err => console.error('SEARCH ERROR: ', err))
        } else {
          setSearchResults(null)
        }
      }, 500),
    )
  }

  /**
   * @param {ChangeEvent} e
   * @return {()=>void}
   */
  const changeSearchString = e => {
    setSearchString(e.target.value)
    if (page !== 1) setPage(1)
    if (scrollHeigthPageIsLoad.length > 0) setScrollHeigthPageIsLoad([])
  }

  const loadMore = heightScroll => {
    if (scrollHeigthPageIsLoad.includes(heightScroll)) return
    setScrollHeigthPageIsLoad([...scrollHeigthPageIsLoad, heightScroll])
    setPage(page + 1)
    sendSearchRequest(searchString, page + 1).catch(err => console.error('SEARCH ERROR: ', err))
  }

  const loadMoreScroll = e => {
    const clientHeight = Math.round(e.target.clientHeight)
    const scrollHeight = Math.round(e.target.scrollHeight)
    const bottom = Math.round(scrollHeight - e.target.scrollTop) === clientHeight

    if (bottom) loadMore(scrollHeight)
  }

  return [searchResults, searchString, changeSearchString, loadMoreScroll]
}

/**
 *
 * @param {PortalApi} api
 * @returns {[SearchResult[], (searchElement:SearchResult) => void]} searchHistory, addSearchHistory
 *
 */
const useSearchHistory = api => {
  const [searchHistory, setSearchHistory] = useState(null)

  const getSearchHistory = async () => {
    const { request } = api.getSearchHistory()
    const response = await request
    if (response && response.length) {
      setSearchHistory(response)
    }
  }

  useEffect(() => {
    getSearchHistory()
  }, [])

  const addSearchHistory = async searchElement => {
    const { request } = api.saveSearchHistory(searchElement._id)
    await request

    if (!searchHistory) setSearchHistory([searchElement])
    else {
      if (searchHistory.find(el => el._id === searchElement._id)) {
        setSearchHistory([
          searchElement,
          ...searchHistory.filter(el => el._id !== searchElement._id),
        ])
        return
      }
      if (searchHistory.length >= 6) searchHistory.pop()
      setSearchHistory([searchElement, ...searchHistory])
    }
  }

  return [searchHistory, addSearchHistory]
}

export { useSearchResults, useSearchHistory }

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'

import PageData from '~shared/utils/PageData'
import withWidth from '@material-ui/core/withWidth'

import Colors from '~shared/assets/styles/colors'
import classNames from 'classnames'

import { Link, NavLink, withRouter } from 'react-router-dom'

import Constant from '~shared/utils/Constants'

import PortalLabels from '~src/constants/PortalLabels'
import IntlUtil from '~shared/utils/IntlUtil'

const styles = ({ breakpoints, spacing: { unit } }) => ({
  item: {
    boxSizing: 'border-box',
    marginBottom: '10px',
    height: 'auto',

    '&.active $itemText': {
      color: 'rgba(255, 255, 255, 0.4)',
    },

    '&.active': {
      pointerEvents: 'none',
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },

    [breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  itemSelected: {
    backgroundColor: Colors.Aqua020 + ' !important',
  },
  nestedItem: {
    paddingLeft: unit * 3 + 'px !important',
  },
  menuIconSelected: {
    color: Colors.Brand,
  },
  subDivider: {
    marginLeft: unit * 2,
  },
  itemText: {
    paddingLeft: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    // width: '125px',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',

    color: Colors.White,
    textTransform: 'uppercase',

    [breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
    },
  },
  itemTextContainer: {
    paddingLeft: 0,
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'inherit',
    width: Constant.drawerWidth,
    height: '100%',
    background:
      'linear-gradient(167.41deg, #BDAAD8 0%, #A952B5 23.25%, #7958A4 62.53%, #EBB2C0 99.22%)',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  xsOnly: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  platformIcon: {
    maxHeight: unit * 5,
    padding: '6px 0 6px',
    maxWidth: '100%',
    [breakpoints.down('xs')]: {
      maxHeight: unit * 4,
    },
  },
  mobilePoints: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobilePointsContainer: {
    backgroundColor: Colors.Grey008,
  },
  close: {
    marginLeft: unit * 3 + 'px !important',
    marginTop: unit + 6 + 'px !important',
    marginBottom: '20px',

    '& > div': {
      backgroundColor: 'transparent !important',
    },

    [breakpoints.down('xs')]: {
      marginLeft: '25px !important',
    },
  },
  scrollStyle: {
    scrollbarColor: 'rgba(0, 0, 0, 0.10) transparent',
    scrollbarWidth: '8px',
    scrollbarFaceColor: 'rgba(0, 0, 0, 0.10)',
    scrollbarBaseColor: 'transparent',

    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.10)',
      borderRadius: '4px',
    },
  },
})

const MainMenu = withStyles(styles)(
  class extends React.Component {
    constructor(props) {
      super(props)

      const activeMenuItem = this.props.data.find(menuItem => {
        return (
          menuItem.items &&
          menuItem.items.find(item => window.location.pathname.startsWith(item.to))
        )
      })

      this.state = {}

      if (activeMenuItem) {
        this.state[activeMenuItem.label] = true
      }
    }

    static defaultProps() {
      return {
        data: [],
      }
    }

    onLinkClicked = e => {
      const { onOverlayClick } = this.props
      onOverlayClick && onOverlayClick(e)
    }

    renderMenuItem = (menuItem, index, xsOnly = false) => {
      const { classes } = this.props
      let isMenuActive = false

      menuItem._link = menuItem._link || {}

      const to = menuItem._link.delegateTo ? menuItem._link.delegateTo : menuItem.to
      const target = menuItem._link.delegateTo ? '_blank' : undefined

      const createSubMenu = menuItem => {
        if (menuItem.items) {
          return (
            <Collapse in={this.state[menuItem.label]} timeout="auto" unmountOnExit>
              <Divider className={classes.subDivider} />
              {menuItem.items.map((item, k) => {
                let isActive = window.location.pathname.startsWith(item.to)
                isMenuActive = isMenuActive || isActive

                item._link = item._link || {}

                const to = item._link.delegateTo ? item._link.delegateTo : item.to
                const target = item._link.delegateTo ? '_blank' : undefined

                return (
                  <ListItem
                    key={k}
                    component={item._link.delegateTo ? 'a' : Link}
                    to={to}
                    href={to}
                    target={target}
                    button
                    selected={isActive}
                    className={classNames({
                      [classes.itemSelected]: isActive,
                    })}
                    onClick={this.onLinkClicked}
                  >
                    <ListItemText
                      className={classes.nestedItem}
                      primary={item.label}
                      primaryTypographyProps={{ className: classes.itemText }}
                    />
                  </ListItem>
                )
              })}
              <Divider className={classes.subDivider} />
            </Collapse>
          )
        }
      }

      const subMenu = createSubMenu(menuItem)

      return (
        <div key={index + (xsOnly ? '_xs' : '')}>
          <MenuItem
            className={classNames(classes.item, { [classes.xsOnly]: xsOnly })}
            button
            component={menuItem.items ? undefined : menuItem._link.delegateTo ? 'a' : NavLink}
            to={to || '#'}
            href={to || '#'}
            id={menuItem.icon && `main_menu_${menuItem.icon}_link`}
            target={target}
            onClick={this.handleClick(menuItem)}
          >
            <ListItemIcon>
              {/*<Icon className={classNames({ [classes.menuIconSelected]: isMenuActive })}>*/}
              {/*  {menuItem.icon}*/}
              {/*</Icon>*/}
            </ListItemIcon>
            <ListItemText
              inset
              primary={menuItem.label}
              className={classes.itemTextContainer}
              primaryTypographyProps={{ className: classes.itemText }}
            />
            {menuItem.items ? <Icon>{this.state[menuItem.label] ? 'less' : 'more'}</Icon> : null}
          </MenuItem>

          {subMenu}
        </div>
      )
    }

    handleClick = ({ items, label }) => e => {
      if (!items) {
        this.onLinkClicked(e)
      }

      this.setState({ [label]: !this.state[label] })
    }

    render() {
      const {
        classes,
        data = [],
        open,
        className,
        onOverlayClick,
        apps,
        member,
        menuIcon,
        headerHightOffset,
        width,
      } = this.props

      const isXs = width === 'xs'

      let { settings: { general: { platformIcon } = {} } = {} } = PageData.appFrame || {}

      const { jpoints = [] } = member

      return (
        <Drawer
          className={className}
          classes={{ paper: classNames(classes.drawerPaper, classes.scrollStyle) }}
          anchor="left"
          open={open}
          onClose={onOverlayClick}
        >
          <div
            style={{
              width: '100%',
              marginTop: isXs ? headerHightOffset - 30 - 18 - 20 : headerHightOffset / 2 - 18 - 20,
            }}
          >
            <div className={classes.close}>{menuIcon}</div>

            <List>{[...data.map((item, index) => this.renderMenuItem(item, index))]}</List>
          </div>
          {/*<Divider />*/}
          {apps &&
            apps.length > 0 &&
            apps.map(a => (
              <a href={a.to} target="_blank">
                <ListItem button>
                  <ListItemIcon>
                    <Icon>{a.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText
                    inset
                    className={classes.itemTextContainer}
                    primary={a.name}
                    primaryTypographyProps={{ className: classes.itemText }}
                  />
                </ListItem>
              </a>
            ))}
          <List>
            <ListItem
              key="logout"
              button
              id={'main_menu_logout'}
              onClick={() => {
                const { api } = this.props
                api.logout()
              }}
              className={classes.item}
            >
              <ListItemIcon>{/*<Icon>{'exit_to_app'}</Icon>*/}</ListItemIcon>
              <ListItemText
                inset
                className={classes.itemTextContainer}
                primary={IntlUtil.label(PortalLabels.LOG_OUT)}
                primaryTypographyProps={{ className: classes.itemText }}
              />
            </ListItem>
          </List>
        </Drawer>
      )
    }
  },
)

export default withWidth()(withRouter(MainMenu))
